import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey};
    opacity: 0.7;
    margin-bottom: 4px;
  }

  > input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;

    &:focus {
      border-color: #094ab2;
    }
  }

  > span {
    position: absolute;
    top: 45px;
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
  }
`;

export const ResultsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  > div {
    padding: 8px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover,
    &:focus {
      background: #e5eef5;
    }

    &:active {
      background: #cce0f5;
    }
  }
`;
