import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { setDate, setMonth, addMinutes, isWithinInterval } from 'date-fns';
import { FaLock, FaCopyright, FaUser } from 'react-icons/fa';

import { toast } from 'react-toastify';
import base64 from 'base-64';
import { usePortalAuth } from '~/hooks';

import history from '~/services/history';

import getValidationErrors from '~/util/getValidationErrors';

import { ButtonSpinner } from '../../../components/Spinner';
import Input from './Input';

import defaultBackground from '../../../assets/default.jpg';
import newYearBackground from '../../../assets/new-year.jpg';
import downBackground from '../../../assets/down.jpg';
import easterBackground from '../../../assets/easter.jpg';
import motherBackground from '../../../assets/mother.jpg';
import fatherBackground from '../../../assets/father.jpg';
import yellowSeptemberBackground from '../../../assets/yellow-september.jpg';
import pinkOctoberBackground from '../../../assets/pink-october.jpg';
import blueNovemberBackground from '../../../assets/blue-november.jpg';
import christmasBackground from '../../../assets/christmas.jpg';
import accountingBackground from '../../../assets/accounting.jpg';

import defaultLogo from '../../../assets/sign-in-logo.svg';

import { Container, Background, Content, AnimationContainer } from './styles';

const SignIn = () => {
  const { company, loadCompany, signIn } = usePortalAuth();

  const defaultUser = localStorage.getItem('@Diretiva:portal:user')
    ? base64.decode(localStorage.getItem('@Diretiva:portal:user'))
    : '';
  const defaultPassword = localStorage.getItem('@Diretiva:portal:password')
    ? base64.decode(localStorage.getItem('@Diretiva:portal:password'))
    : '';

  const formRef = useRef(null);

  const [backgroundLoading, setBackgroundLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('#01579b');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!company) return;

    setBackgroundLoading(true);

    const currentDate = addMinutes(new Date(), 1);
    const background = document.getElementById('background');
    const logo = document.getElementById('logo');

    if (company.logo) {
      logo.setAttribute('src', company.logo_url);
    } else {
      logo.setAttribute('src', defaultLogo);
    }

    if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 0), 1),
        end: setDate(setMonth(new Date(), 0), 15),
      })
    ) {
      background.style.background = `url(${newYearBackground}) no-repeat center`;
      setMessage(`
        <p>Embora ninguém possa volta atrás e fazer</p>
        <p>um novo começo, qualquer um pode começar</p>
        <p>agora e fazer um novo fim. Feliz Ano novo!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 2), 15),
        end: setDate(setMonth(new Date(), 2), 31),
      })
    ) {
      background.style.background = `url(${downBackground}) no-repeat center`;
      setMessage(`
        <p>21/03</p>
        <p>Dia Internacional da Síndrome de Down</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 3), 1),
        end: setDate(setMonth(new Date(), 3), 10),
      })
    ) {
      background.style.background = `url(${easterBackground}) no-repeat center`;
      setMessage(`
        <p>Eu sou a ressurreição e a vida.</p>
        <p>Quem crê em mim viverá para sempre!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 3), 20),
        end: setDate(setMonth(new Date(), 3), 30),
      })
    ) {
      background.style.background = `url(${accountingBackground}) no-repeat center`;
      setMessage(`
        <p>Abril é mês dos Profissionais </p>
        <p>da Área Contábil. </p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 4), 1),
        end: setDate(setMonth(new Date(), 4), 15),
      })
    ) {
      background.style.background = `url(${motherBackground}) no-repeat center`;
      setMessage(`
        <p>Maio</p>
        <p>Mês das mães</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 7), 1),
        end: setDate(setMonth(new Date(), 7), 15),
      })
    ) {
      background.style.background = `url(${fatherBackground}) no-repeat center`;
      setMessage(`
        <p>Agosto</p>
        <p>Mês dos pais</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 8), 1),
        end: setDate(setMonth(new Date(), 8), 15),
      })
    ) {
      background.style.background = `url(${yellowSeptemberBackground}) no-repeat center`;
      setColor('#ffbf00');
      setMessage(`
        <p>Setembro Amarelo</p>
        <p>Mês de prevenção ao suicídio.</p>
        <p>Peça ajuda. Ligue 188.</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 8), 20),
        end: setDate(setMonth(new Date(), 8), 30),
      })
    ) {
      background.style.background = `url(${accountingBackground}) no-repeat center`;
      setMessage(`
        <p>Setembro é mês dos Contadores.</p>
        <p>Parabéns a todos os profissionais da área!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 9), 1),
        end: setDate(setMonth(new Date(), 9), 15),
      })
    ) {
      background.style.background = `url(${pinkOctoberBackground}) no-repeat center`;
      setColor('#FE5088');
      setMessage(`
        <p>Outubro Rosa</p>
        <p>Mês de prevenção ao câncer de mama.</p>
        <p>Cuide-se: faça o autoexame!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 10), 1),
        end: setDate(setMonth(new Date(), 10), 15),
      })
    ) {
      background.style.background = `url(${blueNovemberBackground}) no-repeat center`;
      setColor('#01579b');
      setMessage(`
        <p>Novembrol Azul</p>
        <p>Mês de prevenção ao câncer de próstata</p>
        <p>Cuide-se: procure seu urologista!</p>
      `);
    } else if (
      isWithinInterval(currentDate, {
        start: setDate(setMonth(new Date(), 11), 1),
        end: setDate(setMonth(new Date(), 11), 31),
      })
    ) {
      background.style.background = `url(${christmasBackground}) no-repeat center`;
      setColor('#d3081b');
      setMessage(`
        <p>Hoje, na cidade de Davi, nasceu para vós</p>
        <p>o Salvador, que é o Cristo Senhor!</p>
      `);
    } else {
      background.style.background = `url(${defaultBackground}) no-repeat center`;
      setMessage(`
        <p>Portal do Empreendedor</p>
      `);
    }

    setBackgroundLoading(false);
  }, [company]);

  const handleSubmit = useCallback(
    async ({ user, password }) => {
      try {
        setLoading(true);

        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          user: Yup.string()
            .required('O e-mail é obrigatório')
            .test('len', 'Digite um usuário válido', val => val.length === 11),
          password: Yup.string().required('A senha é obrigatória'),
        });

        user = user.replace(/[^0-9]+/g, '');

        await schema.validate(
          { user, password },
          {
            abortEarly: false,
          }
        );

        await signIn({
          user,
          password,
        });

        history.push('/portal');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          toast.error(errors.user || errors.password);

          formRef.current.setErrors(errors);
        } else {
          toast.error(err.response.data.message);
        }

        setLoading(false);
      }
    },
    [signIn]
  );

  useEffect(() => {
    (async () => {
      if (!company) {
        await loadCompany();
      } else if (defaultUser && defaultPassword) {
        handleSubmit({ user: defaultUser, password: defaultPassword });
        localStorage.removeItem('@Diretiva:portal:user');
        localStorage.removeItem('@Diretiva:portal:password');
      } else {
        localStorage.removeItem('@Diretiva:portal:user');
        localStorage.removeItem('@Diretiva:portal:password');
      }
    })();
  }, [company, loadCompany, defaultUser, defaultPassword, handleSubmit]);

  return (
    <Container loading={loading ? 1 : 0}>
      <Content>
        <AnimationContainer color={color}>
          <img id="logo" alt="logo" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{company?.name || ''}</h1>

            <Input
              name="user"
              icon={FaUser}
              type="text"
              placeholder="Usuário"
              color={color}
              defaultValue={defaultUser}
            />

            <Input
              name="password"
              icon={FaLock}
              type="password"
              placeholder="Senha"
              color={color}
              defaultValue={defaultPassword}
            />

            <button type="submit">
              {loading ? <ButtonSpinner size={14} /> : 'Entrar'}
            </button>
          </Form>
        </AnimationContainer>
        <footer>
          <div>
            <FaCopyright />
            <strong>2020 - Contabilizar Digital</strong>
          </div>
          <span>Todos os direitos reservados</span>
        </footer>
      </Content>
      {!backgroundLoading && (
        <Background id="background">
          <div
            className="message"
            dangerouslySetInnerHTML={{ __html: message }}
          />

          <div className="slogan">
            <p>Tenha todo o controle </p>
            <p>de sua empresa em um só lugar.</p>
          </div>
        </Background>
      )}
    </Container>
  );
};

export default SignIn;
