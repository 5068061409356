import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

const TextAreaPopUp = ({ name, ...rest }) => {
  const textareaRef = useRef(null);
  const { fieldName, registerField, error, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      getValue: ref => ref.value,
      setValue: (ref, value) => {
        ref.value = value;
      },
      clearValue: ref => {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <textarea
        ref={textareaRef}
        name={name}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <span>{error}</span>}
    </>
  );
};

export default TextAreaPopUp;
