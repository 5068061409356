import styled, { css } from 'styled-components';

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  margin-left: 16px;

  label {
    position: absolute;
    right: 3px;
    top: 0;
    z-index: 1;
    font-size: 7px;
    color: #fff;
  }

  ${props =>
    props.hasOpenTickets &&
    css`
      & {
        svg {
          color: ${({ theme }) => theme.colors.success} !important;
        }

        label {
          color: #000;
          font-weight: bold;
        }
      }
    `}

  &:hover span {
    visibility: visible;
    top: 180%;
    opacity: 1;
  }

  span {
    visibility: hidden;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fcfcfc;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10002;
    top: 150%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.8s;
  }

  span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.primary}
      transparent;
  }
`;
