const formatValueOnChange = value => {
  const inputValue = value.replace(/\D/g, ''); // Remove não números
  // Adicione zeros à esquerda para garantir que tenhamos um número com 2 casas decimais
  const paddedValue = inputValue.padStart(3, '');

  // Separe os últimos 2 dígitos (centavos) do resto do valor
  const cents = paddedValue.slice(-2);
  const dollars = paddedValue.slice(0, -2);

  // Formate os dólares com ponto e vírgula a cada 3 dígitos
  const formattedDollars = dollars.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  // Crie o valor final com a formatação BRL
  const formattedValue = `R$ ${formattedDollars},${cents}`;

  return formattedValue;
};

export default formatValueOnChange;
