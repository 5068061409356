import React, { useState, useEffect, useMemo, useCallback } from 'react';
import socketio from 'socket.io-client';
import { parseISO, formatDistance, addDays, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import {
  FaBell,
  FaCalendarDay,
  FaTimes,
  FaLocationArrow,
  FaComment,
  FaRegCalendarTimes,
  FaCalendarCheck,
  FaCalendarPlus,
  FaClipboardList,
  FaCalendar,
  FaExclamationTriangle,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
import ReactInputMask from 'react-input-mask';
import base64 from 'base-64';

import { useAuth } from '~/hooks';

import ClearBackground from '~/components/ClearBackground';
import { ButtonSpinner } from '~/components/Spinner';
import api from '../../../services/api';
import history from '../../../services/history';
import { socketUrl } from '../../../services/socketUrl';

import {
  Container,
  Badge,
  Title,
  NotificationList,
  Scroll,
  Notification,
  Icon,
  NotificationContent,
  Buttons,
  ViewButton,
  MaskAsReadButton,
  RequestButtonsContainer,
  Justification,
  KPIFeedback,
  RequestExtensionDate,
} from './styles';
import TicketModal from '../Ticket/TicketModal';

const Notifications = () => {
  const { token, changeCompany, company, user } = useAuth();

  const [notifications, setNotifications] = useState([]);
  const [numberOfNotifications, setNumberOfNotifications] = useState(null);
  const [visible, setVisible] = useState(false);
  const [refuseRequestLoading, setRefuseRequestLoading] = useState(false);
  const [justification, setJustification] = useState('');
  const [acceptKPIConclusionLoading, setAcceptKPIConclusionLoading] = useState(
    false
  );
  const [extentTo, setExtentTo] = useState('');
  const [
    acceptRequestExtesionLoading,
    setAcceptRequestExtesionLoading,
  ] = useState(false);
  const [acceptKPIMessage, setAcceptKPIMessage] = useState('');
  const [acceptKPINote, setAcceptKPINote] = useState(5);
  const [showTickets, setShowTickets] = useState(false);
  const [ticketTargetId, setTicketTargetId] = useState('');

  const hasNotifications = useMemo(() => {
    if (notifications.length === 0) {
      return false;
    }

    setNumberOfNotifications(notifications.length);
    return true;
  }, [notifications]);

  const socket = useMemo(() => {
    if (user) {
      socketio(`${socketUrl}`, {
        query: {
          user_id: user.id,
        },
        transports: ['websocket'],
      });
    }
  }, [user]);

  useEffect(() => {
    if (socket) {
      socket.on('new-notification', notification => {
        notification.firstName =
          notification.sender_name === null
            ? 'SISTEMA'
            : notification.sender_name.split(' ', 1);
        notification.timeDistance = formatDistance(
          parseISO(notification.created_at),
          new Date(),
          { addSuffix: true, locale: pt }
        );
        notification.refused = false;
        setNotifications([notification, ...notifications]);
      });
    }
  }, [socket, notifications]);

  useEffect(() => {
    if (socket) {
      socket.on('delete-notification', id => {
        setNotifications(oldNotifications =>
          oldNotifications.filter(item => item.target_id !== id)
        );
      });
    }
  }, [socket]);

  async function loadNotifications() {
    const response = await api.get('notifications');

    if (response.data.length > 0) {
      const data = response.data.map(notification => ({
        ...notification,
        firstName:
          notification.sender_name === null
            ? 'SISTEMA'
            : notification.sender_name.split(' ', 1),
        timeDistance: formatDistance(
          parseISO(notification.notification_date),
          new Date(),
          { addSuffix: true, locale: pt }
        ),
        refused: false,
        acceptKPI: false,
      }));

      setNotifications(data);
    }
  }

  useEffect(() => {
    loadNotifications();
    const interval = setInterval(() => {
      loadNotifications();
    }, 600000);
    return () => clearInterval(interval);
  }, []);

  const handleVisible = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handleMarkAsRead = useCallback(async id => {
    setNotifications(oldNotifications =>
      oldNotifications.filter(item => item.id !== id)
    );

    await api.put(`notifications/${id}`);
  }, []);

  const handleViewButton = useCallback(
    async (targetCompanyId, url) => {
      if (company) {
        if (targetCompanyId !== company.id && url !== '/support') {
          await changeCompany(targetCompanyId, url, token);
        } else if (url.startsWith('/ticket')) {
          setTicketTargetId(base64.decode(url.replace('/ticket/view/', '')));
          setShowTickets(true);
        } else {
          history.push(`${url}`);
        }
        setVisible(false);
      }
    },
    [company, changeCompany, token]
  );

  const handleAcceptRequestConclusion = useCallback(
    async (notificationId, targetId, created_at) => {
      await api.put(`schedule/done/${targetId}`, {
        done: true,
        conclusion_date: created_at,
      });

      handleMarkAsRead(notificationId);
    },
    [handleMarkAsRead]
  );

  const handleShowRequestExtesionDate = useCallback(
    async (id, targetId) => {
      const appointment = await api.get(`schedule/${targetId}`);

      const { deadline_date } = appointment.data;

      setExtentTo(() => {
        return format(addDays(parseISO(deadline_date), 3), 'dd/MM/yyyy');
      });

      setNotifications(
        notifications.map(notification =>
          notification.id === id
            ? { ...notification, acceptExtension: true }
            : notification
        )
      );
    },
    [notifications]
  );

  const handleAcceptRequestExtension = useCallback(
    async (notificationId, targetId) => {
      setAcceptRequestExtesionLoading(true);

      setNotifications(oldNotifications =>
        oldNotifications.filter(item => item.id !== notificationId)
      );

      await api.put(`schedule/extent-to/${targetId}`, {
        extent_to: extentTo,
      });

      setAcceptRequestExtesionLoading(false);
      handleMarkAsRead(notificationId);
    },
    [extentTo, handleMarkAsRead]
  );

  const handleRefusedConclusion = useCallback(
    id => {
      setNotifications(
        notifications.map(notification =>
          notification.id === id
            ? { ...notification, refused: true }
            : notification
        )
      );
    },
    [notifications]
  );

  const handleJustification = useCallback(
    async (id, target_id, notification_type) => {
      if (user && company) {
        try {
          setRefuseRequestLoading(true);

          const data = {
            type: 0,
            company_id: company.id,
            schedule_id: target_id,
            user_id: user.id,
            content: `Indeferido - ${justification}`,
            notification_type,
          };

          await api.post('schedule/feedback', data);
          if (notification_type === 'refuse-request-conclusion') {
            await api.put(`schedule/request-conclusion/${target_id}`, {
              request_conclusion: false,
            });
          } else if (notification_type === 'refuse-request-extension') {
            await api.put(`schedule/request-extension/${target_id}`, {
              request_extension: false,
            });
          }

          handleMarkAsRead(id);

          setRefuseRequestLoading(false);
        } catch (err) {
          toast.error('Falha ao justificar recusa de compromisso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setRefuseRequestLoading(false);
        }
      }
    },
    [company, handleMarkAsRead, justification, user]
  );

  const handleAcceptKPIConclusion = useCallback(
    id => {
      setNotifications(
        notifications.map(notification =>
          notification.id === id
            ? { ...notification, acceptKPI: true }
            : notification
        )
      );
    },
    [notifications]
  );

  const handleKPIConclusionMessage = useCallback(
    async (notificationId, targetId, sender_id, created_at, company_id) => {
      if (user) {
        try {
          setAcceptKPIConclusionLoading(true);

          const data = {
            notification_id: notificationId,
            schedule_id: targetId,
            user_id: sender_id,
            sender_id: user.id,
            conclusion_date: created_at,
            company_id,
            message: acceptKPIMessage,
            note: acceptKPINote,
          };

          await api.post('notifications/accept-kpi', data);

          setAcceptKPIConclusionLoading(false);

          handleMarkAsRead(notificationId);
        } catch {
          setAcceptKPIConclusionLoading(false);

          toast.error('Falha ao aceitar conclusão.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [handleMarkAsRead, user, acceptKPIMessage, acceptKPINote]
  );

  const enforceMinMax = useCallback(el => {
    if (el.value !== '') {
      if (Number(el.value) < Number(el.min)) {
        el.value = el.min;
      }
      if (Number(el.value) > Number(el.max)) {
        el.value = el.max;
      }
    }
  }, []);

  return (
    <>
      {hasNotifications ? (
        <Helmet title={`Diretiva1 (${numberOfNotifications}) `} />
      ) : (
        <Helmet title="Diretiva1" />
      )}
      {showTickets && (
        <TicketModal
          showTickets={showTickets}
          setShowTickets={() => setShowTickets(!showTickets)}
          targetId={ticketTargetId}
        />
      )}
      <Container visible={visible}>
        <Badge hasNotifications={hasNotifications} onClick={handleVisible}>
          <FaBell size={18} color="#fcfcfc" />
          {hasNotifications && <label>{numberOfNotifications}</label>}
          <span>Notificações</span>
        </Badge>

        <NotificationList visible={visible}>
          <Title>
            <div>
              <FaBell size={18} color="#01579B" />
              <h3>Notificações</h3>
            </div>

            <FaTimes size={18} color="#ee4256" onClick={handleVisible} />
          </Title>

          <Scroll>
            {hasNotifications ? (
              notifications.map(notification => (
                <Notification key={notification.id}>
                  {notification.type === 'new-appointment' && (
                    <>
                      <Icon>
                        <FaCalendarPlus color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> atribui um
                          novo compromisso para você.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'edit-appointment' && (
                    <>
                      <Icon>
                        <FaCalendarPlus color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> editou um
                          compromisso.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'new-feedback' && (
                    <>
                      <Icon>
                        <FaComment color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          Novo feedback de{' '}
                          <strong>{notification.firstName}</strong>.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'request-conclusion' && (
                    <>
                      <Icon>
                        <FaCalendarCheck color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> solicitou
                          <span className="conclusion">conclusão</span> de
                          compromisso.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <RequestButtonsContainer>
                            <button
                              type="button"
                              onClick={() =>
                                handleAcceptRequestConclusion(
                                  notification.id,
                                  notification.target_id,
                                  notification.created_at
                                )
                              }
                            >
                              Aceitar
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleRefusedConclusion(notification.id)
                              }
                            >
                              Recusar
                            </button>
                          </RequestButtonsContainer>
                        </Buttons>
                        <Justification visible={notification.refused}>
                          <input
                            name="justification"
                            label="Justificativa"
                            type="text"
                            onChange={e => setJustification(e.target.value)}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleJustification(
                                notification.id,
                                notification.target_id,
                                'refuse-request-conclusion'
                              )
                            }
                          >
                            {refuseRequestLoading ? (
                              <ButtonSpinner size={8} />
                            ) : (
                              <FaLocationArrow size={8} />
                            )}
                          </button>
                        </Justification>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'request-conclusion-kpi' && (
                    <>
                      <Icon>
                        <FaCalendarCheck color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> solicitou
                          conclusão de compromisso.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <RequestButtonsContainer>
                            <button
                              type="button"
                              onClick={() =>
                                handleAcceptKPIConclusion(notification.id)
                              }
                            >
                              Aceitar
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleRefusedConclusion(notification.id)
                              }
                            >
                              Recusar
                            </button>
                          </RequestButtonsContainer>
                        </Buttons>
                        <KPIFeedback visible={notification.acceptKPI}>
                          <div className="message">
                            <label>Mensagem</label>
                            <input
                              type="text"
                              onChange={e =>
                                setAcceptKPIMessage(e.target.value)
                              }
                            />
                          </div>
                          <div className="note">
                            <label>Nota</label>
                            <input
                              type="number"
                              min={0}
                              max={5}
                              defaultValue={5}
                              placeholder="0 ~ 5"
                              onKeyUp={e => enforceMinMax(e.target)}
                              onChange={e => setAcceptKPINote(e.target.value)}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() =>
                              handleKPIConclusionMessage(
                                notification.id,
                                notification.target_id,
                                notification.sender_id,
                                notification.created_at,
                                notification.company_id
                              )
                            }
                          >
                            {acceptKPIConclusionLoading ? (
                              <ButtonSpinner size={14} />
                            ) : (
                              <FaLocationArrow size={14} />
                            )}
                          </button>
                        </KPIFeedback>
                        <Justification visible={notification.refused}>
                          <div>
                            <label>Justificativa</label>
                            <input
                              name="justification"
                              type="text"
                              onChange={e => setJustification(e.target.value)}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() =>
                              handleJustification(
                                notification.id,
                                notification.target_id,
                                'refuse-request-conclusion'
                              )
                            }
                          >
                            {refuseRequestLoading ? (
                              <ButtonSpinner size={14} />
                            ) : (
                              <FaLocationArrow size={14} />
                            )}
                          </button>
                        </Justification>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'request-extension' && (
                    <>
                      <Icon>
                        <FaCalendarDay color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> solicitou
                          <span className="extension">prorrogação</span> de
                          compromisso.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <RequestButtonsContainer>
                            <button
                              type="button"
                              onClick={() =>
                                handleShowRequestExtesionDate(
                                  notification.id,
                                  notification.target_id
                                )
                              }
                            >
                              Aceitar
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                handleRefusedConclusion(notification.id)
                              }
                            >
                              Recusar
                            </button>
                          </RequestButtonsContainer>
                        </Buttons>
                        <RequestExtensionDate
                          visible={notification.acceptExtension}
                        >
                          <div>
                            <label>Data</label>
                            <ReactInputMask
                              name="extent_to"
                              type="text"
                              mask="99/99/9999"
                              value={extentTo}
                              onChange={e => setExtentTo(e.target.value)}
                            />
                          </div>
                          <button
                            type="button"
                            onClick={() =>
                              handleAcceptRequestExtension(
                                notification.id,
                                notification.target_id
                              )
                            }
                          >
                            {acceptRequestExtesionLoading ? (
                              <ButtonSpinner size={8} />
                            ) : (
                              <FaLocationArrow size={8} />
                            )}
                          </button>
                        </RequestExtensionDate>
                        <Justification visible={notification.refused}>
                          <input
                            name="justification"
                            label="Justificativa"
                            type="text"
                            onChange={e => setJustification(e.target.value)}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleJustification(
                                notification.id,
                                notification.target_id,
                                'refuse-request-extension'
                              )
                            }
                          >
                            {refuseRequestLoading ? (
                              <ButtonSpinner size={8} />
                            ) : (
                              <FaLocationArrow size={8} />
                            )}
                          </button>
                        </Justification>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'conclusion-refused' && (
                    <>
                      <Icon>
                        <FaRegCalendarTimes color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> recusou sua
                          solicitação de conclusão.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'extension-refused' && (
                    <>
                      <Icon>
                        <FaRegCalendarTimes color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> recusou sua
                          solicitação de prorrogação.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {(notification.type === 'new-process' ||
                    notification.type === 'new-labor-process') && (
                    <>
                      <Icon>
                        <FaClipboardList color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> atribui um
                          novo processo{' '}
                          {notification.type === 'new-labor-process' &&
                            'trabalhista'}{' '}
                          para você.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'new-expiration-control' && (
                    <>
                      <Icon>
                        <FaCalendar color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> atribui um
                          novo controle de vencimento para você.
                        </h4>
                        <p>{notification.content}</p>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'new-expiration-controls' && (
                    <>
                      <Icon>
                        <FaCalendar color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          <strong>{notification.firstName}</strong> atribui
                          novos controles de vencimento para você, verifique.
                        </h4>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() =>
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              )
                            }
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                  {notification.type === 'generated-protocols' && (
                    <>
                      <Icon>
                        <FaExclamationTriangle color="#545454" size={28} />
                      </Icon>
                      <NotificationContent>
                        <h4>
                          Você possui protocolos gerados e não enviados,
                          verifique.
                        </h4>
                        <time>{notification.timeDistance}</time>
                        <Buttons>
                          <ViewButton
                            type="button"
                            onClick={() => {
                              handleViewButton(
                                notification.company_id,
                                notification.url
                              );
                              localStorage.setItem(
                                '@Diretiva:protocol:filter:situation',
                                '{"value":0,"label":"Gerado"}'
                              );
                            }}
                          >
                            Visualizar
                          </ViewButton>
                          <MaskAsReadButton
                            type="button"
                            onClick={() => handleMarkAsRead(notification.id)}
                          >
                            Marcar como lido
                          </MaskAsReadButton>
                        </Buttons>
                      </NotificationContent>
                    </>
                  )}
                </Notification>
              ))
            ) : (
              <h3>Não há novas notificações.</h3>
            )}
          </Scroll>
        </NotificationList>
      </Container>
      {visible && (
        <ClearBackground
          onClick={handleVisible}
          style={{ marginLeft: '0px !important' }}
        />
      )}
    </>
  );
};

export default Notifications;
