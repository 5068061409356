import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import copy from 'copy-to-clipboard';
import { Form } from '@unform/web';

import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import TextareaAutosize from 'react-autosize-textarea/lib';
import produce from 'immer';
import {
  FaTimes,
  FaEye,
  FaPlus,
  FaEdit,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaCheck,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaFolderOpen,
  FaFolderPlus,
  FaPrint,
  FaDownload,
  FaFileExport,
  FaTimesCircle,
  FaCog,
  FaCopy,
  FaThumbsUp,
  FaThumbsDown,
  FaCloud,
  FaFolder,
  FaTrash,
  FaSave,
  FaFileUpload,
  FaKeyboard,
  FaLayerGroup,
  FaEnvelope,
  FaFileAlt,
} from 'react-icons/fa';
import fileDownload from 'js-file-download';
import base64 from 'base-64';

import {
  Select,
  Input,
  DatePicker,
  SelectWithFilterActiveCheckbox,
} from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Pagination from '~/components/Pagination';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';
import PermissionComponent from '~/components/PermissionComponent';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import DeliveryProtocol from './DeliveryProtocol';
import ReceiverModal from './ReceiverModal';
import CancellationReasonModal from './CancellationReasonModal';
import MailSettingsModal from './MailSettingsModal';
import DeadFile from './DeadFile';
import ConfirmSendProtocol from './ConfirmSendProtocol';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  ProtocolInfo,
  DateContainer,
  Td,
} from './styles';
import ProtocolGroupModal from './ProtocolInGroupModal';
import DocumentsModal from './DocumentsModal';

const List = () => {
  const { user, company, companyUser, companyUsers } = useAuth();

  const filterRef = useRef(null);
  const changeMethodRef = useRef(null);
  const newViewerRef = useRef(null);

  const [protocolsLoading, setProtocolsLoading] = useState(false);
  const [optionsClientLoading, setOptionsClientLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const [protocolsToPrint, setProtocolsToPrint] = useState([]);
  const [whatPrintType, setWhatPrintType] = useState(0);

  const [protocols, setProtocols] = useState([]);
  const [protocol, setProtocol] = useState(null);

  const [protocolIndex, setProtocolIndex] = useState(0);
  const [totalProtocols, setTotalProtocols] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortConfig, setSortConfig] = useState('');

  const [isEdittingMethod, setIsEdittingMethod] = useState(false);
  const [isCreatingViewer, setIsCreatingViewer] = useState(false);

  const [viewersOptions, setViewersOptions] = useState([]);

  const [receiverModalIsOpen, setReceiverModalIsOpen] = useState(false);
  const [selectedProtocolToModal, setSelectedProtocolToModal] = useState(null);

  const [deadfileSettings, setDeadfileSettings] = useState({});

  const [
    cancellationReasonModalIsOpen,
    setCancellationReasonModalIsOpen,
  ] = useState(false);

  const [mailSettingsModalIsOpen, setMailSettingsModalIsOpen] = useState(false);

  const [usersOptions, setUsersOptions] = useState(null);
  const [clientsOptions, setClientsOptions] = useState(null);
  const methodOptions = useMemo(() => {
    return [
      { value: 0, label: 'Portal' },
      { value: 1, label: 'Impressão' },
      { value: 2, label: 'Upload' },
      { value: 3, label: 'Arquivo morto' },
    ];
  }, []);
  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Gerado' },
      { value: 1, label: 'Enviado' },
      { value: 2, label: 'Entregue' },
      { value: 3, label: 'Impresso' },
      { value: 4, label: 'Impresso/Enviado' },
      { value: 5, label: 'Cancelado' },
    ];
  }, []);

  const [selectedClientOption, setSelectedClientOption] = useState();
  const [selectedUserOption, setSelectedUserOption] = useState(null);
  const [startPeriod, setStartPeriod] = useState(() => {
    const startPeriodStorage = localStorage.getItem(
      '@Diretiva:protocol:filter:start'
    );

    if (startPeriodStorage && startPeriodStorage !== 'null') {
      return new Date(startPeriodStorage);
    }

    return null;
  });
  const [startPeriodForDatePicker, setStartPeriodForDatePicker] = useState(
    () => {
      const startPeriodStorage = localStorage.getItem(
        '@Diretiva:protocol:filter:start'
      );

      if (startPeriodStorage && startPeriodStorage !== 'null') {
        return new Date(startPeriodStorage);
      }

      return null;
    }
  );
  const [endPeriod, setEndPeriod] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:protocol:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return new Date();
  });
  const [endPeriodForDatePicker, setEndPeriodForDatePicker] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:protocol:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return new Date();
  });
  const [selectedSituationOption, setSelectedSituationOption] = useState(() => {
    const situationStorage = localStorage.getItem(
      '@Diretiva:protocol:filter:situation'
    );

    if (situationStorage) {
      return JSON.parse(situationStorage);
    }

    return { value: '', label: 'Todos' };
  });
  const [codSearch, setCodSearch] = useState(() => {
    const codStorage = localStorage.getItem('@Diretiva:protocol:filter:cod');

    if (codStorage) {
      return codStorage;
    }

    return '';
  });

  useEffect(() => {
    if (!user || !company) return;

    (async () => {
      try {
        setOptionsClientLoading(true);
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            notType: 0,
          },
        });

        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          const options = response.data.map(item => ({
            value: item.id,
            label: item.name,
          }));

          options.unshift({
            value: '',
            label: 'Todos',
          });

          setClientsOptions(options);
        } else {
          toast.warn('Nenhum cliente foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setOptionsClientLoading(false);
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })();

    (async () => {
      try {
        const response = await api.get(
          `/protocols/deadfile-settings/${company.id}`
        );

        setDeadfileSettings(response.data);
      } catch {
        toast.error('Falha ao buscar as configurações de arquivo morto.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    })();
  }, [user, company]);

  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: '',
        label: 'Todos',
        active: true,
      });

      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  const handleFilterUsersOptions = useCallback(
    value => {
      if (user && companyUsers) {
        if (value === true) {
          setUsersOptions(oldUsersOptions =>
            oldUsersOptions.filter(userItem => userItem.active !== false)
          );
        } else {
          const options = companyUsers
            .filter(userItem => userItem.user_id !== user.id)
            .filter(userItem => userItem.user_id !== -1)
            .map(userItem => {
              return {
                value: userItem.user_id,
                label: userItem.short_name,
                active: userItem.active,
              };
            });

          options.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          options.unshift({
            value: user.id,
            label: user.short_name,
            active: true,
          });

          options.push({
            value: '',
            label: 'Todos',
            active: true,
          });

          setUsersOptions(options);
        }
      }
    },
    [companyUsers, user]
  );

  useEffect(() => {
    if (user && clientsOptions) {
      const userStorage = localStorage.getItem(
        '@Diretiva:protocol:filter:user'
      );

      if (userStorage) {
        setSelectedUserOption(JSON.parse(userStorage));
      } else {
        setSelectedUserOption({
          value: user.id,
          label: user.short_name,
        });
      }

      const clientStorage = localStorage.getItem(
        '@Diretiva:protocol:filter:client'
      );

      if (clientStorage) {
        setSelectedClientOption(JSON.parse(clientStorage));
      } else {
        setSelectedClientOption(clientsOptions[0]);
      }
    }
  }, [user, clientsOptions]);

  const loadProtocols = useCallback(async () => {
    if (
      (company,
      companyUsers,
      selectedSituationOption,
      selectedClientOption,
      selectedUserOption)
    ) {
      try {
        setShowTable(true);
        setProtocolsLoading(true);

        const response = await api.get('/protocols', {
          params: {
            company_id: company.id,
            cod: codSearch,
            situation: selectedSituationOption.value,
            client_id: selectedClientOption.value,
            user_id: selectedUserOption.value,
            page: currentPage,
            start_period: startPeriod,
            end_period: endPeriod,
            sort_key: sortConfig?.key,
            sort_direction: sortConfig?.direction,
          },
        });

        const { docs, total, pages } = response.data;

        if (docs.length > 0) {
          const data = docs.map(item => {
            const documents = item.documents.map(document => ({
              ...document,
              deadline_formatted: document.deadline_date
                ? format(parseISO(document.deadline_date), 'dd/MM/yyyy')
                : null,
            }));

            const viewers = item.viewers.map(viewer => ({
              ...viewer,
              name: viewer.viewer?.user?.name,
              email: viewer.viewer?.content,
              document: viewer.viewer?.user?.document,
              portal_password: viewer.viewer?.user?.portal_password,
            }));

            const documentViews = item.documentViews.map(documentView => ({
              ...documentView,
              document: documentView.document_id
                ? documents.find(
                    document => document.id === documentView.document_id
                  )
                : null,
              viewer: documentView.relationship_id
                ? viewers.find(
                    viewer =>
                      viewer.relationship_id === documentView.relationship_id
                  )
                : null,
              updated_at: documentView.updated_at
                ? format(
                    parseISO(documentView.updated_at),
                    "dd/MM/yyyy 'às' HH:mm"
                  )
                : null,
            }));

            return {
              ...item,
              created_by:
                companyUsers.find(
                  companyUserItem => companyUserItem.user_id === item.user_id
                )?.name || '',
              created_at: format(parseISO(item.created_at), 'dd/MM/yyyy'),
              delivered_date: item.delivered_date
                ? format(parseISO(item.delivered_date), 'dd/MM/yyyy')
                : null,
              documents,
              viewers,
              documentViews,
            };
          });

          setProtocols(data);
          setProtocol(data[0]);
          setTotalPages(pages);
          setTotalProtocols(total);
        } else {
          setProtocols([]);
          setProtocol([]);
          setTotalPages(1);
          setTotalProtocols(0);
          toast.warn('Nenhum protocolo foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setProtocolsLoading(false);
      } catch {
        toast.error('Falha ao buscar protocolos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setProtocolsLoading(false);
      }
    }
  }, [
    company,
    companyUsers,
    currentPage,
    codSearch,
    endPeriod,
    selectedSituationOption,
    sortConfig,
    startPeriod,
    selectedUserOption,
    selectedClientOption,
  ]);

  useEffect(() => {
    loadProtocols();
  }, [loadProtocols]);

  const alterView = useCallback(() => {
    if (protocols.length > 0) {
      setShowTable(!showTable);
    }

    setIsEdittingMethod(false);
    setIsCreatingViewer(false);
  }, [showTable, protocols.length]);

  const getDetails = useCallback(
    async (id, index) => {
      const protocolItem = protocols.find(item => item.id === id);

      setProtocol(protocolItem);
      setProtocolIndex(index);
      alterView();
    },
    [protocols, alterView]
  );

  const handleFilter = useCallback(
    data => {
      setCurrentPage(1);

      setSelectedUserOption(
        usersOptions.find(option => option.value === data.user)
      );
      localStorage.setItem(
        '@Diretiva:protocol:filter:user',
        JSON.stringify(usersOptions.find(option => option.value === data.user))
      );

      setCodSearch(data.cod);
      localStorage.setItem('@Diretiva:protocol:filter:cod', data.cod);

      setStartPeriod(data.start);
      setStartPeriodForDatePicker(data.start);
      localStorage.setItem('@Diretiva:protocol:filter:start', data.start);

      setEndPeriod(data.end);
      setEndPeriodForDatePicker(data.end);
      localStorage.setItem('@Diretiva:protocol:filter:end', data.end);

      setSelectedSituationOption(
        situationOptions.find(option => option.value === data.situation)
      );
      localStorage.setItem(
        '@Diretiva:protocol:filter:situation',
        JSON.stringify(
          situationOptions.find(option => option.value === data.situation)
        )
      );

      setSelectedClientOption(
        clientsOptions.find(option => option.value === data.client)
      );
      localStorage.setItem(
        '@Diretiva:protocol:filter:client',
        JSON.stringify(
          clientsOptions.find(option => option.value === data.client)
        )
      );
    },
    [usersOptions, situationOptions, clientsOptions]
  );

  const resetFilter = useCallback(() => {
    if (user) {
      setCurrentPage(1);

      filterRef.current.setFieldValue('user', {
        value: user.id,
        label: user.short_name,
      });
      setSelectedUserOption({
        value: user.id,
        label: user.short_name,
      });
      localStorage.removeItem('@Diretiva:protocol:filter:user');

      filterRef.current.clearField('cod');
      setCodSearch('');
      localStorage.removeItem('@Diretiva:protocol:filter:cod');

      filterRef.current.clearField('start');
      setStartPeriod(null);
      setStartPeriodForDatePicker(null);
      localStorage.removeItem('@Diretiva:protocol:filter:start');

      setEndPeriod(new Date());
      setEndPeriodForDatePicker(new Date());
      localStorage.removeItem('@Diretiva:protocol:filter:end');

      filterRef.current.setFieldValue('situation', situationOptions[0]);
      setSelectedSituationOption(situationOptions[0]);
      localStorage.removeItem('@Diretiva:protocol:filter:situation');

      filterRef.current.setFieldValue('client', clientsOptions[0]);
      setSelectedClientOption(clientsOptions[0]);
      localStorage.removeItem('@Diretiva:protocol:filter:client');
    }
  }, [filterRef, user, clientsOptions, situationOptions]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const handlePrevItem = useCallback(async () => {
    if (protocolIndex !== 0) {
      setProtocolIndex(protocolIndex - 1);
      setProtocol(protocols[protocolIndex - 1]);
    } else {
      setProtocolIndex(protocols.length - 1);
      setProtocol(protocols[protocols.length - 1]);
    }

    setIsEdittingMethod(false);
    setIsCreatingViewer(false);
  }, [protocolIndex, protocols]);

  const handleNextItem = useCallback(async () => {
    if (protocolIndex !== protocols.length - 1) {
      setProtocolIndex(protocolIndex + 1);
      setProtocol(protocols[protocolIndex + 1]);
    } else {
      setProtocolIndex(0);
      setProtocol(protocols[0]);
    }

    setIsEdittingMethod(false);
    setIsCreatingViewer(false);
  }, [protocolIndex, protocols]);

  const handleCancelProtocol = useCallback(
    async reason => {
      try {
        setCancellationReasonModalIsOpen(false);
        setLoading(true);

        await api.put(`/protocols/${protocol.id}`, {
          situation: 5,
          cancellation_reason: reason,
        });

        setProtocols(
          produce(protocols, draft => {
            draft[protocolIndex].situation = 5;
            draft[protocolIndex].cancellation_reason = reason;
          })
        );

        setProtocol(item => ({
          ...item,
          situation: 5,
          cancellation_reason: reason,
        }));

        toast.success('Protocolo cancelado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      } catch {
        toast.error('Falha ao cancelar protocolo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    },
    [protocols, protocolIndex, protocol]
  );

  const handleEdit = useCallback(() => {
    if ((user, companyUser)) {
      if (protocol.situation === 0) {
        if (protocol.user_id) {
          if (protocol.user_id !== user.id && companyUser.level < 9) {
            toast.warn('Somente o criador do protocolo pode editá-lo.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            history.push('/protocol/edit', { id: protocol.id });
          }
        } else if (companyUser.level === 9) {
          history.push('/protocol/edit', { id: protocol.id });
        } else {
          toast.warn('Somente o criador do protocolo pode editá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else {
        toast.warn('Não é possível editar pois o protocol já foi enviado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [protocol, user, companyUser]);

  const handleSend = useCallback(
    async (id, index) => {
      try {
        setLoading(true);

        const mail_settings = await api.get(`/mails-smtp/${company.id}`);
        const protocol_mail_settings = await api.get(
          `/protocols/mail-settings/${company.id}`
        );

        if (!mail_settings.data) {
          if (companyUser.level === 9) {
            toast.error('Configurações de email não encontradas.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            history.push('/mail-settings');
          } else {
            toast.error(
              'Configurações de email não encontradas, peça ao administrador para criar.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }
        } else if (!protocol_mail_settings.data) {
          if (companyUser.level === 9) {
            toast.error(
              'Configurações de email dos protocolos não encontradas.',
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            setMailSettingsModalIsOpen(true);
          } else {
            toast.error(
              'Configurações de email dos protocolos não encontradas, peça ao administrador para criar.',
              { position: toast.POSITION.BOTTOM_RIGHT }
            );
          }
        } else {
          const documentsSorted = [...protocols[index].documents].sort(
            (a, b) => {
              if (!a.deadline_date) {
                return 1;
              }
              if (!b.deadline_date) {
                return -1;
              }
              if (a.deadline_date < b.deadline_date) {
                return -1;
              }
              if (a.deadline_date > b.deadline_date) {
                return 1;
              }
              return 0;
            }
          );

          await api.put(`/protocols/send/${id}`, {
            sender: user.id,
            company_id: company.id,
            obs: protocols[index].obs || '',
            documents: documentsSorted.map(document => ({
              id: document.id,
              document_id: document.document_id,
              document: document.document.description,
              competence: document.competence ? document.competence : '',
              deadline: document.deadline_formatted
                ? document.deadline_formatted
                : '',
              price: document.price,
              obs: document.obs,
            })),
            viewers: protocols[index].viewers.map(viewer => ({
              id: viewer.id,
              relationship_id: viewer.relationship_id,
              email: viewer.email,
              document: viewer.document,
              portal_password: viewer.portal_password,
              user: viewer.viewer.user,
            })),
          });

          setProtocols(
            produce(protocols, draft => {
              if (draft[index].situation === 0) {
                draft[index].situation = 1;
              } else if (draft[index].situation === 3) {
                draft[index].situation = 4;
              }

              if (!draft[index].sender?.name) {
                draft[index].sender = { name: user.name };
              }
            })
          );
          if (index === protocolIndex) {
            if (protocol.situation === 0 || protocol.situation === 3) {
              setProtocol(item => ({
                ...item,
                situation: item.situation === 0 ? 1 : 4,
              }));
            }
          }

          toast.success('Protocolo enviado com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setLoading(false);
      } catch (err) {
        toast.error(err.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    },
    [protocolIndex, protocols, company, companyUser, protocol, user]
  );

  const confirmSend = useCallback(
    (protocolId, index) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmSendProtocol
              onClick={() => handleSend(protocolId, index)}
              onClose={onClose}
              protocol={protocols[index]}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleSend, protocols]
  );

  const sort = useCallback(
    key => {
      let direction = 'asc';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      const element = document.getElementById(key);
      element.classList.add(direction);
      setSortConfig({ key, direction });
    },
    [sortConfig]
  );

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  const handleWriteOff = useCallback(
    async (receiverName, receiverDate) => {
      if (selectedProtocolToModal && receiverName.trim() !== '') {
        try {
          setLoading(true);
          setReceiverModalIsOpen(false);

          await api.post('/protocols/document_views', {
            protocol_id: selectedProtocolToModal,
            type: 1,
            name: receiverName,
            hasDownloaded: true,
            updated_at: receiverDate,
          });

          setLoading(false);
          loadProtocols();
          toast.success('Baixa realizada.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } catch {
          toast.error('Falha ao dar baixa.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setReceiverModalIsOpen(false);
          setLoading(false);
        }
      }
    },
    [selectedProtocolToModal, loadProtocols]
  );

  const handlePrint = useCallback(
    async index => {
      try {
        if (!Number.isInteger(index)) {
          const printProtocolsPromises = protocols
            .filter(
              item =>
                item.method === 1 &&
                item.situation !== 2 &&
                item.situation !== 5
            )
            .map(async item => {
              await api.put(`/protocols/print/${item.id}`);
            });

          await Promise.all(printProtocolsPromises);
          loadProtocols();
        } else {
          await api.put(`/protocols/print/${protocols[index].id}`);

          setProtocols(
            produce(protocols, draft => {
              draft[index].situation =
                draft[index].situation === 0 ? 3 : draft[index].situation;
              draft[index].sender = { short_name: user.short_name };
            })
          );

          if (protocolIndex === index) {
            setProtocol(item => ({
              ...item,
              situation:
                protocols[index].situation === 0
                  ? 3
                  : protocols[index].situation,
            }));
          }
        }

        window.print();
      } catch {
        toast.error('Falha ao imprimir.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [protocols, protocolIndex, loadProtocols, user]
  );

  const confirmPrint = useCallback(
    (index, type) => {
      if (Number.isInteger(index)) {
        setProtocolsToPrint([protocols[index]]);
        setWhatPrintType(type);
      } else {
        setProtocolsToPrint(
          protocols.filter(
            item =>
              item.method === 1 && item.situation !== 2 && item.situation !== 5
          )
        );
        setWhatPrintType(0);
      }

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handlePrint(index)}
              onClose={onClose}
              message="Deseja imprimir o(s) protocolo(s)? Esta ação não pode ser desfeita"
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handlePrint, protocols]
  );

  const copyLink = useCallback(
    (id, email, viewer) => {
      const params = base64.encode(
        JSON.stringify({
          id,
          email,
        })
      );

      copy(
        `Protocolo ${protocol.cod} - ${protocol.client.name}\n\n${viewer}\n\nhttps://app.diretiva1.com.br/portal/${params}`
      );
    },
    [protocol]
  );

  const loadRelated = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/relationships/related/${protocol.client_id}`
      );

      const { data } = response;

      if (data.length > 0) {
        const options = [];

        data.forEach(item => {
          item.related_contacts.forEach(contact => {
            options.push({
              value: contact.id,
              relationship_id: item.info.id,
              email: contact.content,
              name: item.info.name,
              label: `${item.info.name} - ${contact.content}`,
            });
          });
        });

        setViewersOptions(options);
        setIsCreatingViewer(true);
        setLoading(false);
      } else {
        setLoading(false);
        toast.warn('Nenhuma pessoa relacionada foi encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch {
      setLoading(false);
      toast.error('Falha ao buscar pessoas relacionadas.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [protocol]);

  const handleChangeMethod = useCallback(
    async ({ method }) => {
      try {
        setLoading(true);

        await api.put(`/protocols/${protocol.id}`, { method });

        setProtocol(
          produce(protocol, draft => {
            draft.method = method;
          })
        );

        setProtocols(
          produce(protocols, draft => {
            draft[protocolIndex].method = method;
          })
        );

        setIsEdittingMethod(false);
        setLoading(false);
        toast.success('Método editado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        setLoading(false);
        toast.error('Falha ao editar o método.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [protocol, protocols, protocolIndex]
  );

  const handleCreateViewer = useCallback(
    async ({ viewer }) => {
      try {
        setLoading(true);

        const { relationship_id, name, email } = viewersOptions.find(
          item => item.value === viewer
        );

        const newViewer = await api.post('/protocols/viewers', {
          contact_id: viewer,
          relationship_id,
          protocol_id: protocol.id,
        });

        setProtocol(
          produce(protocol, draft => {
            draft.viewers.push({
              id: newViewer.data.id,
              name,
              email,
            });
          })
        );

        setProtocols(
          produce(protocols, draft => {
            draft[protocolIndex].viewers.push({
              id: newViewer.data.id,
              name,
              email,
            });
          })
        );

        setIsCreatingViewer(false);
        setLoading(false);
        toast.success('Destinatário criado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        setLoading(false);
        toast.error('Falha ao criar destinatário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [protocol, protocols, protocolIndex, viewersOptions]
  );

  const handleDeleteViewer = useCallback(
    async id => {
      try {
        setLoading(true);

        await api.delete(`/protocols/viewers/${id}`);

        setProtocol(
          produce(protocol, draft => {
            draft.viewers = draft.viewers.filter(item => item.id !== id);
          })
        );

        setProtocols(
          produce(protocols, draft => {
            draft[protocolIndex].viewers = draft[protocolIndex].viewers.filter(
              item => item.id !== id
            );
          })
        );

        setLoading(false);
        toast.success('Destinatário excluído com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        setLoading(false);
        toast.error('Falha ao excluir destinatário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [protocol, protocols, protocolIndex]
  );

  const confirmDeleteViewer = useCallback(
    id => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteViewer(id)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteViewer]
  );

  const handleDeleteDocument = useCallback(
    async id => {
      try {
        setLoading(true);

        await api.delete(`/protocols/documents/${id}`);

        setProtocol(
          produce(protocol, draft => {
            draft.documents = draft.documents.filter(item => item.id !== id);
          })
        );

        setProtocols(
          produce(protocols, draft => {
            draft[protocolIndex].documents = draft[
              protocolIndex
            ].documents.filter(item => item.id !== id);
          })
        );

        setLoading(false);
        toast.success('Documento excluído com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch {
        setLoading(false);
        toast.error('Falha ao excluir documento.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    },
    [protocol, protocols, protocolIndex]
  );

  const confirmDeleteDocument = useCallback(
    id => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteDocument(id)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteDocument]
  );

  const [protocolGroupModalIsOpen, setProtocolGroupModalIsOpen] = useState(
    false
  );

  const [documentsModalIsOpen, setDocumentsModalIsOpen] = useState(false);
  const filterForDocumentsModal = useRef({});

  const handleOpenDocumentsModal = useCallback(() => {
    const client_id = filterRef.current.getFieldValue('client');
    const user_id = filterRef.current.getFieldValue('user');
    const cod = filterRef.current.getFieldValue('cod');
    const situation = filterRef.current.getFieldValue('situation');
    const start_period = filterRef.current.getFieldValue('start');
    const end_period = filterRef.current.getFieldValue('end');

    filterForDocumentsModal.current = {
      situation,
      client_id,
      user_id,
      company_id: company.id,
      cod,
      start_period,
      end_period,
    };

    setDocumentsModalIsOpen(true);
  }, [company]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaFolderOpen size={20} color="#44546a" />
            <h1>Protocolos</h1>
          </div>
          <aside>
            <PermissionComponent level={9}>
              <button
                type="button"
                onClick={() => setMailSettingsModalIsOpen(true)}
              >
                <FaCog size={20} color="#44546a" />
              </button>
            </PermissionComponent>
            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <Link to={{ pathname: '/protocol/new', state: { id: null } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>
          <button
            type="button"
            onClick={() => setProtocolGroupModalIsOpen(true)}
          >
            <FaLayerGroup />
            <span>Grupos</span>
          </button>

          {showTable ? (
            <>
              <button type="button" onClick={resetFilter}>
                <FaEraser />
                <span>Limpar filtros</span>
              </button>
              {protocols.some(
                item =>
                  item.method === 1 &&
                  (item.situation === 0 || item.situation === 3)
              ) && (
                <button type="button" onClick={confirmPrint}>
                  <FaPrint />
                  <span>Imprimir</span>
                </button>
              )}
            </>
          ) : (
            <>
              <div>
                <button type="button" onClick={handlePrevItem}>
                  <FaChevronLeft />
                </button>

                <span>
                  {protocolIndex + 1} de {protocols.length}
                </span>

                <button type="button" onClick={handleNextItem}>
                  <FaChevronRight />
                </button>
              </div>

              {protocol.situation === 0 && (
                <button type="button" onClick={handleEdit}>
                  <FaEdit />
                  <span>Editar</span>
                </button>
              )}

              {!showTable &&
                protocol.situation !== 5 &&
                (protocol.method === 1 || protocol.method === 3) && (
                  <button
                    type="button"
                    onClick={() =>
                      confirmPrint(protocolIndex, protocol.method === 1 ? 0 : 1)
                    }
                  >
                    <FaPrint />
                    <span>Imprimir</span>
                  </button>
                )}

              {protocol.situation !== 5 && (
                <button
                  type="button"
                  onClick={() => {
                    setCancellationReasonModalIsOpen(true);
                  }}
                >
                  <FaTimesCircle />
                  <span>Cancelar</span>
                </button>
              )}

              {protocol.situation !== 5 &&
                protocol.method !== 3 &&
                protocol.documents.length > 0 &&
                protocol.viewers.length > 0 && (
                  <button
                    type="button"
                    onClick={() => confirmSend(protocol.id, protocolIndex)}
                  >
                    <FaFileExport />
                    <span>
                      {protocol.situation === 0 || protocol.situation === 3
                        ? 'Enviar'
                        : 'Reenviar'}
                    </span>
                  </button>
                )}
            </>
          )}
        </Controls>
        {showTable && selectedClientOption && selectedUserOption && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <Input
              name="cod"
              className="cod"
              label="Cód."
              type="number"
              defaultValue={codSearch}
            />

            <Select
              label="Cliente"
              name="client"
              className="client"
              options={clientsOptions}
              defaultValue={selectedClientOption}
            />

            <DateContainer className="period">
              <label>Período</label>
              <div>
                <DatePicker
                  name="start"
                  selected={startPeriodForDatePicker}
                  onChange={date => setStartPeriodForDatePicker(date)}
                />
                <span>até</span>
                <DatePicker
                  name="end"
                  selected={endPeriodForDatePicker}
                  onChange={date => setEndPeriodForDatePicker(date)}
                />
              </div>
            </DateContainer>

            <Select
              label="Situação"
              name="situation"
              className="situation"
              options={situationOptions}
              defaultValue={selectedSituationOption}
            />

            <SelectWithFilterActiveCheckbox
              label="Usuário"
              name="user"
              className="user"
              options={usersOptions}
              defaultValue={selectedUserOption}
              handleFilter={handleFilterUsersOptions}
            />

            <button type="button" onClick={handleOpenDocumentsModal}>
              <FaFileAlt />
              <span>Documentos</span>
            </button>

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}
        {protocolsLoading || optionsClientLoading ? (
          <TableLoading />
        ) : (
          <Content className="content" id="sua_div">
            {showTable ? (
              <>
                <ReactTooltip
                  id="option"
                  place="bottom"
                  type="info"
                  backgroundColor="#337ab7"
                  effect="solid"
                />
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="thumb" />
                      <th className="method" />
                      <th className="created-in" />
                      <th className="cod">Cód.</th>
                      <th
                        id="created_at"
                        className="start_date sort"
                        onClick={() => sort('created_at')}
                      >
                        <div>
                          Data
                          {sortConfig.key !== 'created_at' && <FaSort />}
                          {sortConfig.key === 'created_at' &&
                            sortConfig.direction === 'asc' && <FaSortUp />}
                          {sortConfig.key === 'created_at' &&
                            sortConfig.direction === 'desc' && <FaSortDown />}
                        </div>
                      </th>
                      <th className="client">Cliente</th>
                      <th className="situation">Situação</th>
                      <th className="user">Gerado por</th>
                      <th className="user">Enviado por</th>
                      <th className="recipient">Recebido por</th>
                      <th
                        id="delivered_date"
                        className="delivered_date sort"
                        onClick={() => sort('delivered_date')}
                      >
                        <div>
                          Entregue em
                          {sortConfig.key !== 'delivered_date' && <FaSort />}
                          {sortConfig.key === 'delivered_date' &&
                            sortConfig.direction === 'asc' && <FaSortUp />}
                          {sortConfig.key === 'delivered_date' &&
                            sortConfig.direction === 'desc' && <FaSortDown />}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {protocols.map((item, index) => (
                      <tr key={index} className="hover">
                        <td className="thumb">
                          {item.situation !== 2 && item.situation !== 5 && (
                            <FaThumbsDown color="#E53935" />
                          )}
                          {item.situation === 2 && (
                            <FaThumbsUp color="#006229" />
                          )}
                          {item.situation === 5 && <FaTimes color="#E53935" />}
                        </td>
                        <td className="method">
                          {item.method === 0 && (
                            <>
                              {item.situation !== 5 ? (
                                <FaEnvelope
                                  className="hover"
                                  color="#01579B"
                                  data-tip={
                                    item.situation === 0 ? 'Enviar' : 'Reenviar'
                                  }
                                  data-for="option"
                                  onClick={() => confirmSend(item.id, index)}
                                />
                              ) : (
                                <FaEnvelope color="#44546a" />
                              )}
                            </>
                          )}
                          {item.method === 1 && (
                            <>
                              {item.situation === 0 && (
                                <FaPrint
                                  className="hover"
                                  color="#006229"
                                  data-tip="Imprimir"
                                  data-for="option"
                                  onClick={() => confirmPrint(index, 0)}
                                />
                              )}
                              {(item.situation === 3 ||
                                item.situation === 4) && (
                                <FaPrint
                                  className="hover"
                                  color="#006229"
                                  data-tip="Dar baixa"
                                  data-for="option"
                                  onClick={() => {
                                    setSelectedProtocolToModal(item.id);
                                    setReceiverModalIsOpen(true);
                                  }}
                                />
                              )}
                              {(item.situation === 2 ||
                                item.situation === 5) && (
                                <FaPrint color="#44546a" />
                              )}
                            </>
                          )}
                          {item.method === 2 && <FaCloud color="#44546a" />}
                          {item.method === 3 && (
                            <>
                              {item.situation === 0 && (
                                <FaFolder
                                  className="hover"
                                  color="#006229"
                                  data-tip="Imprimir"
                                  data-for="option"
                                  onClick={() => confirmPrint(index, 1)}
                                />
                              )}
                              {item.situation === 3 && (
                                <FaFolder
                                  className="hover"
                                  color="#006229"
                                  data-tip="Dar baixa"
                                  data-for="option"
                                  onClick={() => {
                                    setSelectedProtocolToModal(item.id);
                                    setReceiverModalIsOpen(true);
                                  }}
                                />
                              )}
                              {item.situation !== 3 && item.situation !== 0 && (
                                <FaFolder color="#44546a" />
                              )}
                            </>
                          )}
                        </td>
                        <td className="created-in">
                          {item.createdIn === 1 ? (
                            <FaFileUpload
                              color="#44546a"
                              data-tip="Nubo"
                              data-for="option"
                            />
                          ) : (
                            <FaKeyboard
                              color="#44546a"
                              data-tip="Manual"
                              data-for="option"
                            />
                          )}
                        </td>
                        <Td
                          className="cod"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {item.cod}
                        </Td>
                        <Td
                          className="start_date"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {item.created_at}
                        </Td>
                        <Td
                          className="client"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {item.client.name}
                        </Td>
                        <Td
                          className="situation"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {item.situation === 0 && (
                            <FaFolderPlus color="#01579B" />
                          )}
                          {item.situation === 1 && (
                            <FaFileExport color="#01579B" />
                          )}
                          {item.situation === 2 && <FaCheck color="#00c853" />}
                          {item.situation === 3 && <FaPrint color="#44546a" />}
                          {item.situation === 4 && <FaPrint color="#44546a" />}
                          {item.situation === 5 && <FaTimes color="#e53935" />}
                          <span style={{ marginLeft: '5px' }}>
                            {
                              situationOptions.find(
                                situation => situation.value === item.situation
                              ).label
                            }
                          </span>
                        </Td>
                        <Td
                          className="user"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {usersOptions.find(
                            option => option.value === item.user_id
                          )?.label || ''}
                        </Td>
                        <Td
                          className="user"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {item.sender?.short_name || ''}
                        </Td>
                        <Td
                          className="recipient"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {item.receiver}
                        </Td>
                        <Td
                          className="delivered_date"
                          onClick={() => getDetails(item.id, index)}
                        >
                          {item.delivered_date}
                        </Td>
                      </tr>
                    ))}
                  </tbody>
                </TableContainer>
              </>
            ) : (
              <DetailsContainer>
                {protocol && (
                  <>
                    <ProtocolInfo>
                      <h4>PROTOCOLO</h4>
                      <section>
                        <div className="cod">
                          <label>Cód.</label>
                          <input
                            name="cod"
                            value={protocol.cod || ''}
                            readOnly
                          />
                        </div>
                        <div className="date">
                          <label>Data de início</label>
                          <input
                            name="start_date"
                            value={protocol.created_at || ''}
                            readOnly
                          />
                        </div>

                        <div className="client">
                          <label>Cliente</label>
                          <input
                            name="client"
                            value={protocol.client.name || ''}
                            readOnly
                          />
                        </div>

                        <div className="situation">
                          <label>Situação</label>
                          <input
                            name="situation"
                            value={
                              situationOptions.find(
                                situation =>
                                  situation.value === protocol.situation
                              ).label || ''
                            }
                            readOnly
                          />
                        </div>

                        {protocol.situation === 5 && (
                          <div className="cancel">
                            <label>Motivo</label>
                            <input
                              name="cancelation_reason"
                              value={
                                protocol.cancellation_reason ||
                                'Motivo não esclarecido'
                              }
                              readOnly
                            />
                          </div>
                        )}
                      </section>
                      <section>
                        <div className="obs">
                          <label>Mensagem no corpo do email</label>
                          <TextareaAutosize
                            name="obs"
                            value={protocol.obs || ''}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        {protocol.situation === 0 &&
                          protocol.user_id === user.id && (
                            <>
                              {isEdittingMethod ? (
                                <div className="edit">
                                  <FaSave
                                    onClick={() =>
                                      changeMethodRef.current.submitForm()
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="edit">
                                  <FaEdit
                                    onClick={() => setIsEdittingMethod(true)}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        {isEdittingMethod ? (
                          <Form
                            className="method"
                            ref={changeMethodRef}
                            onSubmit={handleChangeMethod}
                          >
                            <Select
                              name="method"
                              label="Método"
                              options={methodOptions}
                              defaultValue={methodOptions.find(
                                method => method.value === protocol.method
                              )}
                            />
                          </Form>
                        ) : (
                          <div className="method">
                            <label>Método</label>
                            <input
                              name="method"
                              value={
                                methodOptions.find(
                                  method => method.value === protocol.method
                                ).label || ''
                              }
                              readOnly
                            />
                          </div>
                        )}
                        <div className="checkbox">
                          <label>Trabalhista</label>
                          <input
                            name="labor"
                            checked={protocol.labor}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Tributário</label>
                          <input
                            name="tributary"
                            checked={protocol.tributary}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Contábil</label>
                          <input
                            name="accounting"
                            checked={protocol.accounting}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Financeiro</label>
                          <input
                            name="financial"
                            checked={protocol.financial}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="checkbox">
                          <label>Administração</label>
                          <input
                            name="administration"
                            checked={protocol.administration}
                            type="checkbox"
                            disabled
                          />
                        </div>
                      </section>

                      <div className="create">
                        <h4>ENVIAR PARA </h4>
                        {protocol.situation === 0 &&
                          protocol.user_id === user.id && (
                            <>
                              {isCreatingViewer ? (
                                <button
                                  type="button"
                                  onClick={() => setIsCreatingViewer(false)}
                                >
                                  <FaTimes size={10} />
                                </button>
                              ) : (
                                <button type="button" onClick={loadRelated}>
                                  <FaPlus size={10} />
                                </button>
                              )}
                            </>
                          )}
                      </div>
                      {protocol.viewers.map(item => (
                        <section key={item.id}>
                          {protocol.situation === 0 &&
                            protocol.user_id === user.id && (
                              <div className="delete">
                                <FaTrash
                                  onClick={() => confirmDeleteViewer(item.id)}
                                />
                              </div>
                            )}
                          <div className="user">
                            <label>Usuário</label>
                            <input
                              name="user"
                              value={item.name || ''}
                              readOnly
                            />
                          </div>
                          <div className="email">
                            <label>Email</label>
                            <input
                              name="email"
                              value={item.email || ''}
                              readOnly
                            />
                          </div>
                          <button
                            className="copy"
                            type="button"
                            // onClick={() => copyLink(item)}
                            onClick={() =>
                              copyLink(protocol.id, item.email, item.name)
                            }
                          >
                            <FaCopy />
                            <span>Copiar link</span>
                          </button>
                        </section>
                      ))}

                      {isCreatingViewer && (
                        <section>
                          <div className="edit">
                            <FaSave
                              onClick={() => newViewerRef.current.submitForm()}
                            />
                          </div>
                          <Form
                            className="new-viewer"
                            onSubmit={handleCreateViewer}
                            ref={newViewerRef}
                          >
                            <Select
                              name="viewer"
                              label="Novo destinatário"
                              placeholder="Selecione um destinatário"
                              options={viewersOptions}
                            />
                          </Form>
                        </section>
                      )}

                      <h4>DOCUMENTOS</h4>
                      {protocol.documents.map((item, index) => (
                        <div key={index}>
                          <section>
                            {protocol.situation === 0 &&
                              protocol.documents.length > 1 &&
                              protocol.user_id === user.id && (
                                <div className="delete">
                                  <FaTrash
                                    onClick={() =>
                                      confirmDeleteDocument(item.id)
                                    }
                                  />
                                </div>
                              )}
                            <div className="document">
                              <label>Documento</label>
                              <input
                                name="document"
                                value={item.document.description || ''}
                                readOnly
                              />
                            </div>
                            <div className="comp">
                              <label>Comp.</label>
                              <input
                                name="date"
                                value={item.competence || ''}
                                readOnly
                              />
                            </div>
                            {protocol.method === 3 && (
                              <div className="comp">
                                <label>Comp. (fim)</label>
                                <input
                                  name="date"
                                  value={item.final_competence || ''}
                                  readOnly
                                />
                              </div>
                            )}
                            <div className="deadline">
                              <label>Vencimento</label>
                              <input
                                name="deadline_date"
                                value={item.deadline_formatted || ''}
                                readOnly
                              />
                            </div>
                            <div className="price">
                              <label>Valor</label>
                              <input
                                name="price"
                                value={item.price || ''}
                                readOnly
                              />
                            </div>
                            {item.file && (
                              <div className="file">
                                <aside>
                                  <button
                                    type="button"
                                    onClick={() => openFile(item.file_url)}
                                    title="Visualizar arquivo"
                                  >
                                    <FaEye size={16} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      downloadFile(item.file_url, item.file)
                                    }
                                    title="Baixar arquivo"
                                  >
                                    <FaDownload size={16} />
                                  </button>
                                </aside>
                                <div>
                                  <label>Arquivo</label>
                                  <input
                                    name="file"
                                    value={item.file || ''}
                                    readOnly
                                  />
                                </div>
                              </div>
                            )}
                          </section>
                          <section className="documents">
                            <div className="document-obs">
                              <label>Observação</label>
                              <TextareaAutosize
                                name="obs"
                                value={item.obs || ''}
                                readOnly
                              />
                            </div>

                            {item.substitute_cod && (
                              <div className="substitute">
                                <label>Substituição</label>
                                <input
                                  name="substitute"
                                  value={`${
                                    item.substituted
                                      ? 'Substituído por'
                                      : 'Substituindo protocolo'
                                  } ${item.substitute_cod}`}
                                  style={{
                                    color: item.substituted
                                      ? '#e53935'
                                      : '#00c853',
                                  }}
                                  readOnly
                                />
                              </div>
                            )}
                          </section>
                        </div>
                      ))}

                      <h4>VIZUALIZAÇÕES NO PORTAL E BAIXAS</h4>
                      {protocol.documentViews
                        .filter(documentView => documentView.hasDownloaded)
                        .map((item, index) => (
                          <section key={index}>
                            <div className="user">
                              <label>Usuário</label>
                              <input
                                name="user"
                                value={
                                  item.type === 0 ? item.viewer.name : item.name
                                }
                                readOnly
                              />
                            </div>
                            <div className="document">
                              <label>
                                {item.type === 0 ? 'Documento' : 'Tipo'}
                              </label>
                              <input
                                name="document"
                                value={
                                  item.type === 0
                                    ? item.document.document?.description
                                    : 'Documentos impressos'
                                }
                                readOnly
                              />
                            </div>
                            <div className="date_hour">
                              <label>Data/Hora</label>
                              <input
                                name="date"
                                value={item.updated_at || ''}
                                readOnly
                              />
                            </div>
                          </section>
                        ))}
                    </ProtocolInfo>
                  </>
                )}
              </DetailsContainer>
            )}
          </Content>
        )}
        <Pagination
          loading={protocolsLoading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalProtocols}
          handlePage={handlePage}
        />
      </Container>

      {loading && <Loading />}

      {!protocolGroupModalIsOpen &&
        ((protocolsToPrint.length > 0 && showTable) ||
          (!showTable && protocol.method !== 3)) &&
        whatPrintType === 0 && (
          <DeliveryProtocol protocols={protocolsToPrint} company={company} />
        )}

      {!protocolGroupModalIsOpen &&
        protocolsToPrint[0]?.method === 3 &&
        whatPrintType === 1 && (
          <DeadFile
            protocol={protocolsToPrint[0]}
            company={company}
            settings={deadfileSettings}
          />
        )}

      {receiverModalIsOpen && (
        <ReceiverModal
          setIsOpen={() => setReceiverModalIsOpen(false)}
          handleWriteOff={handleWriteOff}
          protocolCod={
            protocols.find(item => item.id === selectedProtocolToModal)?.cod
          }
          client={
            protocols.find(item => item.id === selectedProtocolToModal)?.client
              .name
          }
          method={
            protocols.find(item => item.id === selectedProtocolToModal)?.method
          }
        />
      )}

      {cancellationReasonModalIsOpen && (
        <CancellationReasonModal
          setIsOpen={() => setCancellationReasonModalIsOpen(false)}
          handleCancel={handleCancelProtocol}
        />
      )}

      {mailSettingsModalIsOpen && (
        <MailSettingsModal
          isOpen={mailSettingsModalIsOpen}
          setIsOpen={() => setMailSettingsModalIsOpen(false)}
          deadfileSettings={deadfileSettings}
          setDeadfileSettings={setDeadfileSettings}
        />
      )}

      {protocolGroupModalIsOpen && (
        <ProtocolGroupModal
          setIsOpen={() => setProtocolGroupModalIsOpen(false)}
          isOpen={protocolGroupModalIsOpen}
        />
      )}

      {documentsModalIsOpen && (
        <DocumentsModal
          isOpen={documentsModalIsOpen}
          setIsOpen={() => setDocumentsModalIsOpen(false)}
          filter={filterForDocumentsModal.current}
        />
      )}
    </>
  );
};

export default List;
