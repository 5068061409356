import { useState, useCallback } from 'react';
import api from '~/services/api';

const useHasOpenTickets = (company, companyUser, user) => {
  const [hasOpenTickets, setHasOpenTickets] = useState(false);

  const fetchHasOpenTickets = useCallback(async () => {
    if (company && companyUser) {
      const response = await api.get('ticket/hasopentickets', {
        params: {
          company_id: company.id,
          recipient_id: user.id,
        },
      });
      if (response.data.hasOpenTickets !== undefined) {
        setHasOpenTickets(response.data.hasOpenTickets);
        return response.data.hasOpenTickets;
      }
    }

    return false;
  }, [company, companyUser, user]);

  return { hasOpenTickets, fetchHasOpenTickets };
};

export default useHasOpenTickets;
