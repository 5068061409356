import React, { useCallback, useRef, useState } from 'react';
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Select, DatePicker, Input, InputMask } from '~/components/Form';
import Loading from '~/components/Loading';

import { useProcessAuth } from '~/hooks';
import api from '~/services/api';
import history from '~/services/history';

import { FormContainer, Button, BackButton, Options } from './styles';

const Form = () => {
  const { user, processModel, company } = useProcessAuth();

  const [employee, setEmployee] = useState({ name: '', situation: null });
  const [searchEmployeeLoading, setSearchEmployeeLoading] = useState(false);
  const employeeSituationsOptions = [
    { value: 0, label: 'Admitido' },
    { value: 1, label: 'Demitido' },
    { value: null, label: '' },
  ];
  const [checkboxState, setCheckboxState] = useState(false);

  const handleCheckboxChange = useCallback(e => {
    setCheckboxState(e.value);
  }, []);

  const formRef = useRef();

  const [createProcessLoading, setCreateProcessLoading] = useState(false);
  const createProcess = useCallback(
    async employeeId => {
      if (!employeeId) return;

      try {
        setCreateProcessLoading(true);

        const processCreatedResponse = await api.post(
          '/process-portal/process',
          {
            processModel_id: processModel.id,
            sender_name: user.name,
            sender_id: user.id,
            company_id: company.id,
            client_id: user.client_id,
            relatedsIds: [user.id],
            employee_id: employeeId,
          }
        );

        const processId = processCreatedResponse.data.id;

        history.push('/process-portal/edit', { id: processId });
      } catch (e) {
        toast.error(
          `Falha ao criar o processo, reinicie a página.${e.message}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } finally {
        setCreateProcessLoading(false);
      }
    },
    [user, company, processModel]
  );

  const handleChangeEmployeeSituation = useCallback(
    async data => {
      try {
        const formattedCpf = data.cpf.replace(/[^0-9]+/g, '');
        data.cpf = formattedCpf;

        const schema = Yup.object().shape({
          cpf: Yup.string()
            .length(11)
            .required('O CPF é obrigatório.')
            .typeError('Preencha o CPF corretamente'),
        });

        await schema.validate(data, { abortEarly: false });

        await api.put(`/process-portal/employee/${employee.id}`, {
          situation: 1,
        });

        await createProcess(employee.id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);

          toast.warn(
            'Tente rolar a pagina para verificar se existem campos a serem preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );

          toast.error(
            'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          toast.error('Falha ao salvar processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [createProcess, employee]
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        const formattedCpf = data.cpf.replace(/[^0-9]+/g, '');
        data.cpf = formattedCpf;

        const day = new Date();
        day.setDate(day.getDate() + 1);
        const date = new Date().getDay();

        const schema = Yup.object()
          .shape({
            cpf: Yup.string()
              .length(11)
              .required('O CPF é obrigatório.')
              .typeError('Preencha o CPF corretamente'),
            name: Yup.string().required('O nome é obrigatório.'),
            admission_date: Yup.date()
              .min(
                day,
                'A data de admissão só é permite a partir de aqui a dois dias.'
              )
              .required('A data de admissão é obrigatória.')
              .typeError('A data de admissão é obrigatória.'),
            first_ctto_date: Yup.number()
              .min(0, 'A quantidade de dias deve ser maior ou igual que 0.')
              .max(91, 'A quantidade de dias deve ser menor ou igual a que 90.')
              .required('A quantidade de dias de contrato é obrigatória.')
              .typeError('A quantidade de dias de contrato é obrigatória.'),
            second_ctto_date: Yup.number()
              .min(0, 'A quantidade de dias deve ser maior ou igual que 0.')
              .max(91, 'A quantidade de dias deve ser menor ou igual a que 90.')
              .required('A quantidade de dias de contrato é obrigatória.')
              .typeError('A quantidade de dias de contrato é obrigatória.'),
          })
          .test(
            'first_ctto_date',
            'A soma dos dias de contrato deve ser menor ou igual a 90.',
            function validateContractDates(value) {
              const { first_ctto_date, second_ctto_date } = value;
              if (
                first_ctto_date &&
                second_ctto_date &&
                first_ctto_date + second_ctto_date > 90
              ) {
                // eslint-disable-next-line react/no-this-in-sfc
                return this.createError({
                  path: 'first_ctto_date',
                  message:
                    'A soma dos dias de contrato deve ser menor ou igual a 90.',
                });
              }
              return true;
            }
          )
          .test(
            'second_ctto_date',
            'A soma dos dias de contrato deve ser menor ou igual a 90.',
            function validateContractDates(value) {
              const { first_ctto_date, second_ctto_date } = value;
              if (
                first_ctto_date &&
                second_ctto_date &&
                first_ctto_date + second_ctto_date > 90
              ) {
                // eslint-disable-next-line react/no-this-in-sfc
                return this.createError({
                  path: 'second_ctto_date',
                  message:
                    'A soma dos dias de contrato deve ser menor ou igual a 90.',
                });
              }
              return true;
            }
          )
          .test(
            'admission_date',
            'Quando o processo é criado na sexta-feira a data de admisão só pode ser apartir de terça-feira.',
            function validateContractDates(value) {
              const { admission_date } = value;
              const ad_day = new Date(admission_date).getDay();
              if (date === 5 && (ad_day > 5 || ad_day < 2)) {
                // eslint-disable-next-line react/no-this-in-sfc
                return this.createError({
                  path: 'admission_date',
                  message:
                    'Quando o processo é criado na sexta-feira a data de admisão só pode ser apartir de terça-feira.',
                });
              }
              return true;
            }
          );

        data.attest_date = data.admission_date;

        if (!checkboxState) {
          data.first_ctto_date = 1;
          data.second_ctto_date = 1;
        }

        await schema.validate(data, { abortEarly: false });

        if (checkboxState) {
          const secondCttoDate = new Date(data.admission_date);
          secondCttoDate.setDate(
            secondCttoDate.getDate() +
              Number(data.second_ctto_date) +
              Number(data.first_ctto_date) -
              1
          );
          data.second_ctto_date = secondCttoDate;

          const firstCttoDate = new Date(data.admission_date);
          firstCttoDate.setDate(
            firstCttoDate.getDate() + Number(data.first_ctto_date) - 1
          );
          data.first_ctto_date = firstCttoDate;
        }

        data.company_id = company.id;
        data.client_id = user.client_id;
        data.situation = 0;

        const employeeCreated = await api.post(
          '/process-portal/employee',
          data
        );

        await createProcess(employeeCreated.data.id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);

          toast.warn(
            'Tente rolar a pagina para verificar se existem campos a serem preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          toast.error(
            'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          toast.error('Falha ao salvar processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [company, user, createProcess, checkboxState]
  );

  async function handleCheckEmployee(value) {
    const formattedCpf = value.replace(/[^0-9]+/g, '');
    if (formattedCpf.length !== 11) return;

    try {
      setSearchEmployeeLoading(true);
      const { data } = await api.get('/process-portal/employee', {
        params: {
          client_id: user.client_id,
          cpf: formattedCpf,
          company_id: company.id,
        },
      });

      if (processModel.process_type === 1) {
        if (data) {
          setEmployee(data);
        } else {
          toast.warn('Nenhum funcionário encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else if (data) {
        toast.warn('Esse funcionário já está cadastrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        // formRef.current.clearField('cpf');
      }
    } catch (err) {
      toast.error('Falha ao buscar funcionário.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setSearchEmployeeLoading(false);
      const inputElement = document.querySelector('#name');
      if (inputElement) {
        inputElement.focus();
      }
    }
  }

  return (
    <>
      <FormContainer
        ref={formRef}
        onSubmit={
          processModel.process_type === 0
            ? handleSubmit
            : handleChangeEmployeeSituation
        }
      >
        {processModel.process_type === 1 ? (
          <>
            <section>
              <InputMask
                name="cpf"
                label="CPF"
                type="text"
                mask="999.999.999-99"
                onChange={e => handleCheckEmployee(e.target.value)}
                autoFocus
              />
              <Input
                name="name"
                label="Nome"
                type="text"
                value={employee.name}
                disabled
              />
              <Input
                name="situation"
                label="Situação"
                type="text"
                value={
                  employeeSituationsOptions.find(
                    item => item.value === employee.situation
                  ).label
                }
                disabled
              />
            </section>
          </>
        ) : (
          <>
            <section>
              <InputMask
                name="cpf"
                label="CPF"
                type="text"
                mask="999.999.999-99"
                onChange={e => {
                  handleCheckEmployee(e.target.value);
                }}
              />
              <Input
                name="name"
                key="name"
                id="name"
                label="Nome"
                type="text"
              />
              <DatePicker
                name="admission_date"
                className="date"
                label="Data de admissão"
              />
              <Select
                name="select"
                label="Contrato de Experiência"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                placeholder="Escolha Sim ou Não"
                onChange={handleCheckboxChange}
              />
            </section>

            {checkboxState ? (
              <section>
                {/* <DatePicker
                name="attest_date"
                className="date"
                label="Data de atestado"
              /> */}
                <Input
                  name="first_ctto_date"
                  type="number"
                  className="date"
                  label="Qtde de dias da primeira etapa do Ctto. Experiência"
                />
                <Input
                  name="second_ctto_date"
                  type="number"
                  className="date"
                  label="Qtde de dias da segunda etapa do Ctto. Experiência"
                />
              </section>
            ) : (
              <></>
            )}
          </>
        )}
      </FormContainer>

      <Options>
        <Button type="button" onClick={() => formRef.current.submitForm()}>
          Próxima etapa
          <FaAngleDoubleRight size={15} />
        </Button>
        <BackButton
          type="button"
          onClick={() => history.push('/process-portal')}
        >
          <FaAngleDoubleLeft size={15} />
          Voltar
        </BackButton>
      </Options>

      {(searchEmployeeLoading || createProcessLoading) && <Loading />}
    </>
  );
};

export default Form;
