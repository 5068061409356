import styled from 'styled-components';
import { FormContainer } from '~/components/Form';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BilletForm = styled(FormContainer)`
  h4 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.blue};
  }

  section {
    > div {
      & + div {
        margin-left: 16px;
      }
    }
  }

  .expiration_date,
  .emition_date,
  .price,
  .interest,
  .fine,
  .days_to_fine {
    width: 10%;
  }

  .expiration_type,
  .interest_type,
  .fine_type {
    width: 15%;
  }

  .protest,
  .days_to_protest {
    width: 25%;
  }

  .drawee,
  .financial_data {
    width: 35%;
  }

  .message,
  .obs,
  .billet_url {
    width: 100%;
  }
`;

/* export const Event = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;

  .feedback_message {
    width: 40vw;

    textarea {
      width: 40vw;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.success};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;
*/

export const Finances = styled.div`
  margin-top: 16px;
  position: relative;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 16px;
      margin-left: 8px;
      background: none;
      color: ${({ theme }) => theme.colors.blue};
      border: 1px solid ${({ theme }) => theme.colors.blue};
      border-radius: 50%;
      padding: 2px;
      transition: opacity 0.2s;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  > span {
    color: ${({ theme }) => theme.colors.error};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
    top: 20px;
  }
`;

export const Finance = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 16px;
  }

  button {
    position: absolute;
    top: 40%;
    background: none;
    color: ${({ theme }) => theme.colors.blue};
    border: 1px solid ${({ theme }) => theme.colors.blue};
    border-radius: 50%;
    padding: 2px;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.7;
    }
  }

  section {
    padding-left: 16px;
  }

  .address_type {
    margin-left: 0;
    width: 20%;
  }

  .zipcode {
    width: 20%;
  }

  .number {
    width: 10%;
  }

  .street {
    width: 50%;
  }

  .complement,
  .neighborhood,
  .city,
  .state {
    width: 25%;
  }
`;

export const Event = styled.div`
  margin-left: 16px;

  display: flex;
  align-items: center;

  .feedback_message {
    width: 40vw;

    textarea {
      width: 40vw;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 7px;
    border: 1px solid ${({ theme }) => theme.colors.success};
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.white};
    transition: background 0.2s;

    &:hover {
      background: ${({ theme }) => theme.hover.success};
    }

    > svg {
      transform: rotate(45deg);
    }
  }
`;
