import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaTable,
  FaTimes,
  FaEye,
  FaChevronLeft,
  FaChevronRight,
  FaEraser,
  FaSave,
  FaSearch,
  FaDownload,
  FaClipboard,
} from 'react-icons/fa';
import Pagination from '~/components/Pagination';

import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Input, Select } from '~/components/Form';
import NumberFormat from 'react-number-format';
import {
  Container,
  Header,
  Controls,
  Content,
  DetailsContainer,
  Filter,
} from './styles';

const List = () => {
  const { company } = useAuth();

  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const [loading, setLoading] = useState(true);

  const [tableEdit, setTableEdit] = useState(true);

  const [arrayQuery, setArrayQuery] = useState({});

  const [dataCompany, setDataCompany] = useState({});

  const [dataCompanyModifications, setDataCompanyModifications] = useState({});

  const filterRef = useRef(null);
  const tableRef = useRef(null);

  const [nameSearch, setNameSearch] = useState('');
  const [selectedContractType, setSelectedContractType] = useState({
    value: 2,
    label: 'Próprio',
  });

  const contractTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Terceirizado' },
      { value: 2, label: 'Próprio' },
    ];
  }, []);

  const [reportLoading, setReportLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDocs, setTotalDocs] = useState(10);

  useEffect(() => {
    async function loadModels() {
      if (company) {
        try {
          setLoading(true);

          const response = await api.get('revenue', {
            params: {
              page: currentPage,
              year: new Date().getFullYear(),
              order: 1,
              contract_type: selectedContractType.value,
            },
          });

          setArrayQuery(response.data.docs);
          setTotalPages(response.data.pages);
          setTotalDocs(response.data.total);
          setLoading(false);
        } catch (err) {
          toast.error('Falha ao buscar modelos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setLoading(false);
        }
      }
    }

    loadModels();
  }, [company, currentPage, selectedContractType.value]);

  const applyFilters = useCallback(
    async (filters = {}) => {
      setLoading(true);

      const params = {
        page: filters.page || currentPage,
        year: new Date().getFullYear(),
        order: 1,
        contract_type: selectedContractType.value,
        ...filters,
      };

      const response = await api.get('revenue', { params });
      setArrayQuery(response.data.docs);
      setTotalPages(response.data.pages);
      setTotalDocs(response.data.total);
      setLoading(false);
    },
    [currentPage, selectedContractType.value]
  );

  async function handleRowClick(item) {
    setLoading(true);

    const response = await api.get(`revenue/${item.id}`, {
      params: {
        year: new Date().getFullYear(),
      },
    });

    setDataCompany(response.data);
    setDataCompanyModifications(response.data);
    setLoading(false);

    setTableEdit(false);
  }

  async function value() {
    if (tableEdit === true) {
      setLoading(true);

      const response = await api.get(`revenue/${arrayQuery[0].id}`, {
        params: {
          year: new Date().getFullYear(),
        },
      });

      setDataCompany(response.data);
      setDataCompanyModifications(response.data);

      setLoading(false);

      setTableEdit(false);
    } else {
      setLoading(true);

      const response = await api.get('revenue', {
        params: {
          page: currentPage,
          year: new Date().getFullYear(),
          order: 1,
          name: nameSearch,
          contract_type: selectedContractType.value,
        },
      });

      setArrayQuery(response.data.docs);
      setTotalPages(response.data.pages);
      setTotalDocs(response.data.total);

      setLoading(false);

      setTableEdit(true);

      setDataCompany({});
      setDataCompanyModifications({});
    }
  }

  const convertJsonToCsv = jsonData => {
    const csvData = [];
    csvData.push(
      [
        'id',
        'nome',
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ].join(',')
    );

    jsonData.forEach(item => {
      const { id } = item;
      const nome = item.name;
      const monthsValues = Object.values(item.month);

      const rowData = [id, nome, ...monthsValues];

      csvData.push(rowData.join(','));
    });

    const csvString = csvData.join('\n');
    return csvString;
  };

  function saveModifications() {
    dataCompanyModifications.month.forEach(async (item, index) => {
      if (item !== dataCompany.month[index]) {
        const update = {
          id_accountant: dataCompany.id_accountant,

          year: parseInt(dataCompany.year, 10),
          month: index + 1,

          without_billing: item.without_billing,
          cash_sales: item.cash_sales,
          forward_sales: item.forward_sales,
          turnover: item.turnover,
          billing: item.billing,
          overheads: item.overheads,
          the_payroll: item.the_payroll,
        };
        const response = await api.put(`revenue/${dataCompany.id}`, update);

        if (response.status === 204) {
          toast.success('Alterações salvas com sucesso!', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    });
  }

  function exportTable() {
    const csvData = convertJsonToCsv(arrayQuery);

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const printReport = async () => {
    try {
      setReportLoading(true);
      const response = await api.get('revenue', {
        params: {
          page: currentPage,
          year: new Date().getFullYear(),
          order: 1,
          name: nameSearch,
          contract_type: selectedContractType.value,
        },
      });
      if (response.data.docs && response.data.docs.length > 0) {
        setReportData(response.data.docs);
      } else {
        toast.warn('Nenhum dado para relatório encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (err) {
      toast.error('Falha ao gerar relatório.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    if (reportData.length > 0) {
      window.print();
    }
  }, [reportData]);

  const [comment, setComment] = useState('Observação');

  const handleCommentFocus = () => {
    if (comment === 'Observação') {
      setComment('');
    }
  };
  const handleCommentBlur = () => {
    if (comment === '') {
      setComment('Observação');
    }
  };

  function setModifications(month, key, newValue) {
    setDataCompanyModifications(prevState => {
      const newRevenues = prevState.month.map((revenue, index) => {
        if (index === month) {
          return { ...revenue, [key]: newValue };
        }

        return revenue;
      });

      return { ...prevState, month: newRevenues };
    });
  }

  const handleFilterSubmit = useCallback(
    event => {
      event.preventDefault();
      const values = filterRef.current.getData();
      applyFilters(values);
    },
    [filterRef, applyFilters]
  );

  function clearFilters() {
    setNameSearch('');
    setSelectedContractType({ value: 2, label: 'Próprio' });
  }

  const searchByName = () => {
    applyFilters({ name: nameSearch });
  };

  const updateYearData = async newYear => {
    setLoading(true);
    const response = await api.get('revenue', {
      params: {
        page: currentPage,
        year: newYear,
        order: 1,
        name: nameSearch,
        contract_type: selectedContractType.value,
      },
    });
    setArrayQuery(response.data.docs);
    setTotalPages(response.data.pages);
    setTotalDocs(response.data.total);
    setLoading(false);

    setDataCompany(response.data);
    setDataCompanyModifications(response.data);
  };

  const handlePage = page => {
    applyFilters({ page });
    setCurrentPage(page);
  };

  const handleValueChange = (values, index, key) => {
    const { floatValue } = values;
    setModifications(index, key, floatValue);
  };

  const handleCurrencyInput = (e, index, key) => {
    const { value: inputValue } = e.target;
    const formattedValue = inputValue.replace(/\D/g, '');
    const floatValue = parseFloat(formattedValue) / 100;
    setModifications(index, key, floatValue);
  };

  const handlePaste = (e, index, key) => {
    const pastedValue = e.clipboardData.getData('Text');
    const formattedValue = pastedValue.replace(/\D/g, '');
    const floatValue = parseFloat(formattedValue) / 100;
    setModifications(index, key, floatValue);
    e.preventDefault();
  };

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaTable size={20} color="#44546a" />
            <h1>Rotinas Tributárias - Faturamento</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>
        <Controls>
          <button type="button" onClick={value}>
            <FaEye />
            <span>Lista</span>
          </button>

          {!tableEdit ? (
            <button type="button" onClick={saveModifications}>
              <FaSave />
              <span>Salvar</span>
            </button>
          ) : (
            <button type="button" onClick={clearFilters}>
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
          )}
          <button type="button" onClick={printReport}>
            <FaClipboard />
            <span>Relatório</span>
          </button>
          <button type="button" onClick={exportTable}>
            <FaDownload />
            <span>Exportar CSV</span>
          </button>
        </Controls>
        <Filter ref={filterRef} onSubmit={handleFilterSubmit}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'nowrap',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Input
              name="name"
              className="name"
              label="Nome"
              value={nameSearch}
              onChange={e => setNameSearch(e.target.value)}
            />
            <div style={{ width: '10px' }} />
            <Select
              name="contract_type"
              className="contract_type"
              label="Tipo de contrato"
              options={contractTypeOptions}
              defaultValue={{
                value: selectedContractType.value,
                label: selectedContractType.label,
              }}
              onChange={option => setSelectedContractType(option)}
            />
            <button
              type="button"
              onClick={searchByName}
              className="search-name"
            >
              <FaSearch />
            </button>
          </div>

          <div className="year-control">
            <label>Ano</label>
            <div className="year-input-container">
              <button
                className="arrowUser"
                type="button"
                onClick={() => {
                  const newYear = year - 1;
                  setYear(newYear);
                  updateYearData(newYear);
                }}
              >
                <FaChevronLeft />
              </button>
              <input
                type="number"
                value={year}
                onChange={e => setYear(Number(e.target.value))}
                onBlur={() => updateYearData(year)}
                style={{ margin: '0 10px', width: '80px', textAlign: 'center' }}
              />
              <button
                className="arrowUser"
                type="button"
                onClick={() => {
                  const newYear = year + 1;
                  setYear(newYear);
                  updateYearData(newYear);
                }}
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
        </Filter>
        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {tableEdit ? (
              <div ref={tableRef}>
                <TableContainer>
                  <thead>
                    <tr>
                      <th className="description">Empresa</th>
                      {[...Array(12).keys()].map(values => (
                        <th key={values} className="description">
                          {(values + 1).toString().padStart(2, '0')}/{year}
                        </th>
                      ))}
                      <th className="description" />
                    </tr>
                  </thead>
                  <tbody>
                    {arrayQuery &&
                      arrayQuery.map(item => (
                        <tr
                          key={item.id}
                          className="hover"
                          onClick={() => handleRowClick(item)}
                        >
                          <td className="description">{item.name}</td>
                          {Object.values(item.month).map((month, index) => (
                            <td key={index} className="description">
                              <NumberFormat
                                value={month || 0}
                                displayType="text"
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                isNumericString
                                renderText={e => {
                                  const [intPart, decPart] = e.split(',');
                                  return `${intPart},${decPart || '00'}`;
                                }}
                              />
                            </td>
                          ))}
                          <td className="description" />
                        </tr>
                      ))}
                  </tbody>
                </TableContainer>
              </div>
            ) : (
              <DetailsContainer>
                <div>
                  <h4>EMPRESA</h4>
                  <b
                    style={{
                      fontSize: '1.0rem',
                      color: '#4f5a64',
                      marginLeft: '16px',
                    }}
                  >
                    {dataCompanyModifications.name}
                  </b>
                  <section style={{ marginBottom: '16px' }}>
                    <div className="description">
                      <input
                        type="text"
                        value={comment}
                        onFocus={handleCommentFocus}
                        onBlur={handleCommentBlur}
                        onChange={e => setComment(e.target.value)}
                      />
                    </div>
                  </section>
                </div>
                <TableContainer>
                  <h4>RECEITAS</h4>
                  <table
                    style={{
                      width: '70%',
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Mês</th>
                        <th />
                        <th>Vendas à vista</th>
                        <th>Vendas a prazo</th>
                        <th>Vendas Total</th>
                        <th>Compras</th>
                        <th>Despesas Gerais</th>
                        <th>Folha de Pagamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataCompany &&
                        dataCompanyModifications.month.map((revenue, index) => (
                          <tr key={index}>
                            <td>{monthNames[parseInt(index, 10)]}</td>
                            <td>
                              <label
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={revenue.without_billing === true}
                                  onChange={() => {
                                    setModifications(
                                      index,
                                      'without_billing',
                                      !revenue.without_billing
                                    );
                                  }}
                                />
                                <span style={{ marginLeft: '8px' }}>
                                  Sem Faturamento
                                </span>
                              </label>
                            </td>
                            <td>
                              <NumberFormat
                                value={revenue.cash_sales || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                isNumericString
                                onValueChange={values =>
                                  handleValueChange(values, index, 'cash_sales')
                                }
                                onInput={e =>
                                  handleCurrencyInput(e, index, 'cash_sales')
                                }
                                onPaste={e =>
                                  handlePaste(e, index, 'cash_sales')
                                }
                                style={{ textAlign: 'right' }}
                                renderText={e => {
                                  const [intPart, decPart] = e.split(',');
                                  return `${intPart},${decPart || '00'}`;
                                }}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={revenue.forward_sales || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                isNumericString
                                onValueChange={values =>
                                  handleValueChange(
                                    values,
                                    index,
                                    'forward_sales'
                                  )
                                }
                                onInput={e =>
                                  handleCurrencyInput(e, index, 'forward_sales')
                                }
                                onPaste={e =>
                                  handlePaste(e, index, 'forward_sales')
                                }
                                style={{ textAlign: 'right' }}
                                renderText={e => {
                                  const [intPart, decPart] = e.split(',');
                                  return `${intPart},${decPart || '00'}`;
                                }}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={revenue.turnover || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                isNumericString
                                onValueChange={values =>
                                  handleValueChange(values, index, 'turnover')
                                }
                                onInput={e =>
                                  handleCurrencyInput(e, index, 'turnover')
                                }
                                onPaste={e => handlePaste(e, index, 'turnover')}
                                style={{ textAlign: 'right' }}
                                renderText={e => {
                                  const [intPart, decPart] = e.split(',');
                                  return `${intPart},${decPart || '00'}`;
                                }}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={revenue.billing || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                isNumericString
                                onValueChange={values =>
                                  handleValueChange(values, index, 'billing')
                                }
                                onInput={e =>
                                  handleCurrencyInput(e, index, 'billing')
                                }
                                onPaste={e => handlePaste(e, index, 'billing')}
                                style={{ textAlign: 'right' }}
                                renderText={e => {
                                  const [intPart, decPart] = e.split(',');
                                  return `${intPart},${decPart || '00'}`;
                                }}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={revenue.overheads || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                isNumericString
                                onValueChange={values =>
                                  handleValueChange(values, index, 'overheads')
                                }
                                onInput={e =>
                                  handleCurrencyInput(e, index, 'overheads')
                                }
                                onPaste={e =>
                                  handlePaste(e, index, 'overheads')
                                }
                                style={{ textAlign: 'right' }}
                                renderText={e => {
                                  const [intPart, decPart] = e.split(',');
                                  return `${intPart},${decPart || '00'}`;
                                }}
                              />
                            </td>
                            <td>
                              <NumberFormat
                                value={revenue.the_payroll || 0}
                                thousandSeparator="."
                                decimalSeparator=","
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                isNumericString
                                onValueChange={values =>
                                  handleValueChange(
                                    values,
                                    index,
                                    'the_payroll'
                                  )
                                }
                                onInput={e =>
                                  handleCurrencyInput(e, index, 'the_payroll')
                                }
                                onPaste={e =>
                                  handlePaste(e, index, 'the_payroll')
                                }
                                style={{ textAlign: 'right' }}
                                renderText={e => {
                                  const [intPart, decPart] = e.split(',');
                                  return `${intPart},${decPart || '00'}`;
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TableContainer>
              </DetailsContainer>
            )}
          </Content>
        )}
        <Pagination
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalDocs}
          handlePage={handlePage}
        />
      </Container>
      {(loading || reportLoading) && <Loading />}
    </>
  );
};

export default List;
