import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
  background-color: #eee;
  box-shadow: 0px 0px 20px -10px;
`;

export const Forms = styled.div`
  background: #fafafa;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 0 18px #ccc;

  display: flex;
  flex-direction: column;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  border-bottom: 3px solid #eee;

  section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  section:first-child {
    border-radius: 5px 0 0 0;
  }

  section:last-child {
    border-radius: 0 5px 0 0;
  }

  section + section {
    border-left: 3px solid #eee;
  }

  svg {
    margin-right: 5px;
  }

  .concluded {
    background: #aaa;
    color: #fff;
    cursor: no-drop;
  }

  .in-progress {
    background: ${({ theme }) => theme.colors.success};
    color: #fff;
    box-shadow: 0 0 10px #ccc;
  }

  .waiting {
    cursor: no-drop;
  }
`;

export const InputInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;

  svg {
    margin-right: 10px;
  }
  > div {
    width: 90%;
  }
`;

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: calc(100% - 45px);
  overflow: auto;

  section {
    margin-left: 16px;

    display: flex;
    align-items: flex-end;

    & + section {
      margin-top: 16px;
    }

    > div {
      width: 33%;
    }

    > div + div {
      margin-left: 16px;
    }
  }

  h4 {
    color: ${({ theme }) => theme.colors.blue};
    margin-bottom: 16px;
  }

  section + h4 {
    margin-top: 20px;
  }

  .hide {
    display: none;
  }

  .options {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 20px;
  }

  @media (max-width: 650px) {
    section {
      flex-direction: column;

      > div {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    section + section {
      margin-top: 0;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.blue};

  button {
    background: transparent;
    color: ${({ theme }) => theme.colors.error};
    border: 0;
    height: 20px;
    margin: 0 20px 16px 0;

    svg {
      margin-left: 10px;
    }

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  section + & {
    margin-top: 30px;
  }

  h4 {
    height: 20px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;

  background: ${({ theme }) => theme.colors.success};
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background 0.3s;

  &:hover {
    background: ${({ theme }) => theme.hover.success};
  }

  svg {
    margin-left: 10px;
  }

  &:disabled {
    cursor: no-drop;
  }
`;

export const Finish = styled.div`
  height: calc(100% - 45px);
  width: 100%;
  border-radius: 0 0 5px 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.hover.success};

  a,
  span,
  h1 {
    margin-top: 16px;
  }

  a {
    background: ${({ theme }) => theme.hover.success};
    color: ${({ theme }) => theme.colors.white};
    padding: 10px 20px;
    border-radius: 5px;

    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }

  span {
    font-size: 1.2rem;
  }
`;

export const BackButton = styled.button`
  justify-content: center;
  width: max-content;
  margin: 16px 10px 0px;

  background: ${({ theme }) => theme.colors.error};
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  transition: background 0.3s;

  &:hover {
    background: ${({ theme }) => theme.hover.error};
  }

  svg {
    margin-right: 10px;
  }

  &:disabled {
    cursor: no-drop;
  }
`;
