import React, { useState, useEffect } from 'react';
import { FaHeadset } from 'react-icons/fa';
import { useAuth, useHasOpenTickets } from '~/hooks';
import { Badge } from './styles';
import TicketModal from './TicketModal';

const Ticket = () => {
  const { user, company, companyUser } = useAuth();

  const [showTickets, setShowTickets] = useState(false);
  const { hasOpenTickets, fetchHasOpenTickets } = useHasOpenTickets(
    company,
    companyUser,
    user
  );

  useEffect(() => {
    fetchHasOpenTickets();
    const interval = setInterval(() => {
      fetchHasOpenTickets();
    }, 600000);
    return () => clearInterval(interval);
  }, [fetchHasOpenTickets]);

  const handleTickets = () => {
    setShowTickets(!showTickets);
  };

  return (
    <>
      <Badge hasOpenTickets={hasOpenTickets} onClick={handleTickets}>
        <FaHeadset size={18} color="#fcfcfc" />
        <span>Atendimento</span>
      </Badge>
      {showTickets && (
        <TicketModal
          showTickets={showTickets}
          setShowTickets={setShowTickets}
          fetchHasOpenTickets={fetchHasOpenTickets}
        />
      )}
    </>
  );
};

export default Ticket;
