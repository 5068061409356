import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import {
  FaTimes,
  FaEye,
  FaEraser,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  // FaPlus, // WIP
  FaEdit,
  FaTrash,
  FaBarcode,
  // FaLocationArrow,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import { Select } from '~/components/Form';
import { TableLoading } from '~/components/Table';
import Pagination from '~/components/Pagination';

import {
  Container,
  Header,
  Controls,
  Table,
  DetailsContainer,
} from '~/styles/components';
import ConfirmWindow from '~/components/ConfirmWindow';
import { confirmAlert } from 'react-confirm-alert';
import formatValue from '~/util/formatValue';
import { formatValueFloat } from '~/util/formatValueFloat';
import { Filter, Content, BilletInfo } from './styles';

const List = () => {
  const { company, companyUser } = useAuth();
  const history = useHistory();

  const filterRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const [showTable, setShowTable] = useState(true);

  const [billets, setBillets] = useState([]);
  const [billet, setBillet] = useState({});
  const [billetIndex, setBilletIndex] = useState(0);
  const [totalBillets, setTotalBillets] = useState(0);

  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem('@Diretiva:billet:page');

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  const [selectedClient, setSelectedClient] = useState(() => {
    const clientStorage = localStorage.getItem(
      '@Diretiva:billet:filter:drawee'
    );

    if (clientStorage) {
      return JSON.parse(clientStorage);
    }

    return { value: '', label: 'Todos' };
  });

  const [selectedPayStatus, setSelectedPayStatus] = useState(() => {
    const payStatusStorage = localStorage.getItem(
      '@Diretiva:billet:filter:pay_status'
    );

    if (payStatusStorage) {
      return JSON.parse(payStatusStorage);
    }

    return { value: '', label: 'Todos' };
  });

  const [selectedSituation, setSelectedSituation] = useState(() => {
    const situationStorage = localStorage.getItem(
      '@Diretiva:billet:filter:situation'
    );

    if (situationStorage) {
      return JSON.parse(situationStorage);
    }

    return {
      value: 'Aguardando envio para o banco',
      label: 'Aguardando envio para o banco',
    };
  });

  const loadBillets = useCallback(async () => {
    if (company) {
      try {
        setShowTable(true);
        setLoading(true);

        const response = await api.get('/billet', {
          params: {
            company_id: company.old_id,
            page: currentPage,
            drawee: selectedClient.value,
            pay_status: selectedPayStatus.value,
            situation_text: selectedSituation.value,
          },
        });

        if (response.data.docs.length > 0) {
          const formattedBillets = response.data.docs.map(item => ({
            ...item,
            price: formatValue(item.price),
            payed_value: formatValue(item.payed_value),
            amount_credited: formatValue(item.amount_credited),
            interest: formatValueFloat(item.interest),
            fine: formatValueFloat(item.fine),
            expiration_date: item.expiration_date
              ? format(parseISO(item.expiration_date), 'dd/MM/yyyy')
              : '',
            emition_date: item.emition_date
              ? format(parseISO(item.emition_date), 'dd/MM/yyyy')
              : '',
            last_receipt: item.last_receipt
              ? format(parseISO(item.last_receipt), 'dd/MM/yyyy')
              : '',
            situation_text: item.billet_situations.text,
            billet_finance: item.billet_finance.map(billet_finance => ({
              ...billet_finance,
              price: formatValue(billet_finance.price),
            })),
          }));

          setBillets(formattedBillets);
          setTotalPages(response.data.pages);
          setTotalBillets(response.data.total);

          setBillet(formattedBillets[0]);
        } else {
          toast.warn('Nenhum boleto foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } catch {
        toast.error('Falha ao buscar boletos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    }
  }, [
    company,
    currentPage,
    selectedClient,
    selectedPayStatus,
    selectedSituation,
  ]);

  const [situationOptions, setSitationOptions] = useState([]);

  const loadSituations = useCallback(async () => {
    try {
      const response = await api.get('/billet/billet-situations');

      let options = [];
      if (response.data.length > 0) {
        options = response.data
          .map(item => ({
            value: item.text,
            label: item.text,
          }))
          .filter(
            option => option.value !== undefined && option.value !== null
          );
      } else {
        toast.warn('Nenhuma situação de boleto foi encontrada.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }

      options.unshift({ value: '', label: 'Todos' });
      setSitationOptions(options);
    } catch {
      toast.error('Falha ao buscar situações de boleto.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, []);

  const [clientsOptions, setClientsOptions] = useState([]);

  const loadClients = useCallback(async () => {
    if (company) {
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            active: true,
            type: 1,
          },
        });

        let options = [];
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          options = response.data
            .map(item => ({
              value: item.old_id,
              label: item.name,
            }))
            .filter(
              option => option.value !== undefined && option.value !== null
            );
        } else {
          toast.warn('Nenhum cliente foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        options.unshift({ value: '', label: 'Todos' });
        setClientsOptions(options);
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  const payStatusOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Em aberto' },
      { value: 1, label: 'Liquidados' },
    ];
  }, []);

  useEffect(() => {
    loadBillets();
    loadClients();
    loadSituations();
  }, [loadBillets, loadClients, loadSituations]);

  const handleEdit = useCallback(() => {
    if (companyUser.level > 0) history.push(`/billet/edit/${billet.id}`);
  }, [history, billet, companyUser]);

  const handleDelete = useCallback(async () => {
    if (companyUser.level > 0) {
      try {
        await api.delete(`/billet/${billet.id}`);
        toast.success('Boleto excluído com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        loadBillets();
      } catch {
        toast.error('Falha ao excluir boleto.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [companyUser, billet, loadBillets]);

  const confirmDeleteForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={handleDelete}
            onClose={onClose}
            message="Todos os dados serão Deletados com esta ação."
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete]);

  const handleView = useCallback(() => {
    if (billets.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, billets]);

  const handlePrevItem = useCallback(() => {
    if (billetIndex !== 0) {
      setBilletIndex(billetIndex - 1);
      setBillet(billets[billetIndex - 1]);
    } else {
      setBilletIndex(billets.length - 1);
      setBillet(billets[billets.length - 1]);
    }
  }, [billetIndex, billets]);

  const handleNextItem = useCallback(() => {
    if (billetIndex !== billets.length - 1) {
      setBilletIndex(billetIndex + 1);
      setBillet(billets[billetIndex + 1]);
    } else {
      setBilletIndex(0);
      setBillet(billets[0]);
    }
  }, [billetIndex, billets]);

  const handleFilter = useCallback(
    ({ pay_status, situation, drawee }) => {
      setCurrentPage(1);
      localStorage.setItem('@Diretiva:billet:page', 1);

      setSelectedPayStatus(
        payStatusOptions.find(option => option.value === pay_status)
      );
      localStorage.setItem(
        '@Diretiva:billet:filter:pay_status',
        JSON.stringify(
          payStatusOptions.find(option => option.value === pay_status)
        )
      );

      setSelectedSituation(
        situationOptions.find(option => option.value === situation)
      );
      localStorage.setItem(
        '@Diretiva:billet:filter:situation',
        JSON.stringify(
          situationOptions.find(option => option.value === situation)
        )
      );

      setSelectedClient(clientsOptions.find(option => option.value === drawee));
      localStorage.setItem(
        '@Diretiva:billet:filter:drawee',
        JSON.stringify(clientsOptions.find(option => option.value === drawee))
      );
    },
    [payStatusOptions, situationOptions, clientsOptions]
  );

  const handleResetFilter = useCallback(() => {
    setCurrentPage(1);
    localStorage.removeItem('@Diretiva:billet:page');

    filterRef.current.reset();

    filterRef.current.setFieldValue('pay_status', {
      value: '',
      label: 'Todos',
    });
    setSelectedPayStatus({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:billet:filter:pay_status');

    filterRef.current.setFieldValue('situation', {
      value: 'Aguardando envio para o banco',
      label: 'Aguardando envio para o banco',
    });
    setSelectedSituation({
      value: 'Aguardando envio para o banco',
      label: 'Aguardando envio para o banco',
    });
    localStorage.removeItem('@Diretiva:billet:filter:situation');

    filterRef.current.setFieldValue('drawee', {
      value: '',
      label: 'Todos',
    });
    setSelectedClient({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:billet:filter:drawee');
  }, []);

  const handleDetails = useCallback(
    index => {
      setBillet(billets[index]);
      setBilletIndex(index);
      handleView();
    },
    [billets, handleView]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:billet:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem('@Diretiva:billet:page', totalPages);
      } else {
        setCurrentPage(page);
        sessionStorage.setItem('@Diretiva:billet:page', page);
      }
    },
    [totalPages]
  );

  return (
    <Container>
      <Header>
        <div>
          <FaBarcode />
          <h1>Cobrança - Boletos</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={handleView}>
          <FaEye />
          <span>Visualização</span>
        </button>
        {/* <Link to={{ pathname: '/billet/new', state: { id: null } }}> // WIP
          <FaPlus />
          <span>Novo</span>
        </Link> */}
        {showTable ? (
          <button type="button" onClick={handleResetFilter}>
            <FaEraser />
            <span>Limpar filtros</span>
          </button>
        ) : (
          <div>
            <button type="button" onClick={handleEdit}>
              <FaEdit size={15} color="#44546a" />
              <span>Editar</span>
            </button>
            <button type="button" onClick={confirmDeleteForm}>
              <FaTrash size={15} color="#44546a" />
              <span>Excluir</span>
            </button>
            <button type="button" onClick={handlePrevItem}>
              <FaChevronLeft />
            </button>
            {totalBillets > 25 ? (
              <span>{billetIndex + 1} de 25</span>
            ) : (
              <span>
                {billetIndex + 1} de {totalBillets}
              </span>
            )}
            <button type="button" onClick={handleNextItem}>
              <FaChevronRight />
            </button>
          </div>
        )}
      </Controls>
      {showTable && (
        <Filter ref={filterRef} onSubmit={handleFilter}>
          <Select
            label="Cliente"
            name="drawee"
            className="drawee"
            options={clientsOptions}
            defaultValue={selectedClient}
          />
          <Select
            label="Pgto"
            name="pay_status"
            className="pay_status"
            options={payStatusOptions}
            defaultValue={selectedPayStatus}
          />
          <Select
            label="Situação"
            name="situation"
            className="situation"
            options={situationOptions}
            defaultValue={selectedSituation}
          />

          <button type="submit">
            <FaSearch />
          </button>
        </Filter>
      )}
      {loading ? (
        <TableLoading />
      ) : (
        <Content className="content">
          {showTable ? (
            <Table className="table">
              <thead>
                <tr>
                  <th className="expiration_date">Vencimento</th>
                  <th className="financial_data">Dados Financeiros</th>
                  <th className="title_number">Número do Título</th>
                  <th className="drawee">Cliente (Sacado)</th>
                  <th className="price">Preço</th>
                  <th className="situation">Situação</th>
                </tr>
              </thead>
              <tbody>
                {billets?.map((item, index) => (
                  <tr
                    key={item.id}
                    className="hover"
                    onClick={() => handleDetails(index)}
                  >
                    <td className="expiration_date">{item.expiration_date}</td>
                    <td className="financial_data">
                      {item.financial_data.title}
                    </td>
                    <td className="title_number">{item.title_number}</td>
                    <td className="drawee">{item.client.nickname}</td>
                    <td className="price">{item.price}</td>
                    <td className="situation">{item.billet_situations.text}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <DetailsContainer>
              <BilletInfo>
                <h4>Boleto</h4>
                <section>
                  <div className="drawee">
                    <label>Cliente (Sacado)</label>
                    <input
                      name="drawee"
                      value={billet?.client?.name || ''}
                      readOnly
                    />
                  </div>
                  <div className="price">
                    <label>Preço</label>
                    <input name="price" value={billet?.price || ''} readOnly />
                  </div>
                  <div className="emition_date">
                    <label>Emissão</label>
                    <input
                      name="emition_date"
                      value={billet?.emition_date || ''}
                      readOnly
                    />
                  </div>
                  <div className="financial_data">
                    <label>Dados financeiros</label>
                    <input
                      name="financial_data"
                      value={billet?.financial_data?.title || ''}
                      readOnly
                    />
                  </div>
                </section>
                <section>
                  <div className="title_number">
                    <label>Número do título</label>
                    <input
                      name="title_number"
                      value={billet?.title_number || ''}
                      readOnly
                    />
                  </div>
                  <div className="expiration_type">
                    <label>Tipo de vencimento</label>
                    <input
                      name="expiration_type"
                      value={billet?.expiration_type_label || ''}
                      readOnly
                    />
                  </div>
                  <div className="expiration_date">
                    <label>Vencimento</label>
                    <input
                      name="expiration_date"
                      value={billet?.expiration_date || ''}
                      readOnly
                    />
                  </div>
                </section>
                <section>
                  <div className="interest">
                    <label>Juros</label>
                    <input
                      name="interest"
                      value={billet?.interest || ''}
                      readOnly
                    />
                  </div>
                  <div className="interest_type">
                    <label>Juros (Tipo)</label>
                    <input
                      name="interest_type"
                      value={billet?.interest_type_label || ''}
                      readOnly
                    />
                  </div>
                  <div className="fine">
                    <label>Multa</label>
                    <input name="fine" value={billet?.fine || ''} readOnly />
                  </div>
                  <div className="fine_type">
                    <label>Multa (Tipo)</label>
                    <input
                      name="fine_type"
                      value={billet?.fine_type_label || ''}
                      readOnly
                    />
                  </div>
                  <div className="days_to_fine">
                    <label>Dias para multar</label>
                    <input
                      name="days_to_fine"
                      value={billet?.days_to_fine || ''}
                      readOnly
                    />
                  </div>
                  <div className="protest">
                    <label>Protestar</label>
                    <input
                      name="protest"
                      value={billet?.protest_label || ''}
                      readOnly
                    />
                  </div>
                  <div className="days_to_protest">
                    <label>Dias para protestar</label>
                    <input
                      name="days_to_protest"
                      value={billet?.as_days_to_protest?.days_label || 'Nenhum'}
                      readOnly
                    />
                  </div>
                </section>
                <section>
                  <div className="message">
                    <label>Mensagem</label>
                    <input
                      name="message"
                      value={billet?.message || ''}
                      readOnly
                    />
                  </div>
                </section>
                <section>
                  <div className="obs">
                    <label>Observação</label>
                    <input name="obs" value={billet?.obs || ''} readOnly />
                  </div>
                </section>
                <section>
                  <div className="billet_url">
                    <label>Link</label>
                    <input
                      name="billet_url"
                      value={billet?.billet_url || ''}
                      readOnly
                    />
                  </div>
                </section>

                <h4 className="finance_header">Eventos</h4>
                {billet.billet_finance?.length > 0 &&
                  billet.billet_finance.map(billet_finance => (
                    <section key={billet_finance.id}>
                      <div className="finance_name_label">
                        <label>Evento</label>
                        <input
                          name="finance_name_label"
                          value={billet_finance.event?.name_label || ''}
                          readOnly
                        />
                      </div>
                      <div className="finance_competence">
                        <label>Competência</label>
                        <input
                          name="finance_competence"
                          value={billet_finance.name || ''}
                          readOnly
                        />
                      </div>
                      <div className="finance_price">
                        <label>Valor</label>
                        <input
                          name="finance_price"
                          value={billet_finance.price || ''}
                          readOnly
                        />
                      </div>
                    </section>
                  ))}

                <h4 className="situation_header">Situação</h4>
                <section>
                  <div className="situation_text">
                    <label>Situação</label>
                    <input
                      name="situation_text"
                      value={billet?.situation_text || ''}
                      readOnly
                    />
                  </div>
                  <div className="last_receipt">
                    <label>Data da situação</label>
                    <input
                      name="last_receipt"
                      value={billet?.last_receipt || ''}
                      readOnly
                    />
                  </div>
                  <div className="payed_value">
                    <label>Valor pago</label>
                    <input
                      name="payed_value"
                      value={billet?.payed_value || ''}
                      readOnly
                    />
                  </div>
                  <div className="amount_credited">
                    <label>Valor creditado</label>
                    <input
                      name="amount_credited"
                      value={billet?.amount_credited || ''}
                      readOnly
                    />
                  </div>
                </section>
              </BilletInfo>
            </DetailsContainer>
          )}
        </Content>
      )}
      <Pagination
        loading={loading ? 1 : 0}
        currentPage={currentPage}
        pages={totalPages}
        totalDocs={totalBillets}
        handlePage={handlePage}
      />
    </Container>
  );
};

export default List;
