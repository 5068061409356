import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { Container, ResultsContainer } from './styles';

const SearchInput = ({
  name,
  label,
  fetchData,
  className,
  placeholder,
  onChange,
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => ref.value,
      setValue: (ref, value) => {
        ref.value = value;
      },
      clearValue: ref => {
        ref.value = '';
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 400);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearchTerm?.length > 3) {
      const fetchResults = async () => {
        const response = await fetchData(debouncedSearchTerm);
        setResults(response);
      };

      fetchResults();
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm, fetchData]);

  return (
    <Container className={className}>
      {label && <label>{label}</label>}
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={e => {
          setSearchTerm(e.target.value);
          clearError();
          if (onChange) onChange(e.target.value);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 200)}
      />
      {isFocused && results?.length > 0 && (
        <ResultsContainer>
          {results.map(item => (
            <div
              key={item.value}
              role="button"
              tabIndex={0}
              onClick={() => {
                inputRef.current.value = item.value;
                if (onChange)
                  onChange({ value: item.value, label: item.label });
                setSearchTerm('');
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  inputRef.current.value = item.value;
                  if (onChange)
                    onChange({ value: item.value, label: item.label });
                  setSearchTerm('');
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </ResultsContainer>
      )}
      {error && <span>{error}</span>}
    </Container>
  );
};

export default SearchInput;

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

SearchInput.defaultProps = {
  label: null,
  className: null,
  placeholder: 'Digite para buscar...',
  onChange: null,
};
