import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation, Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { toast } from 'react-toastify';

import { confirmAlert } from 'react-confirm-alert';
import {
  FaAddressCard,
  FaTimes,
  FaEye,
  FaPlus,
  FaEraser,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaClipboard,
  FaThumbsUp,
  FaThumbsDown,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import formatDocument from '~/util/formatDocument';
import formatContact from '~/util/formatContact';
import findAndFormatContact from '~/util/findAndFormatContact';

import { Input, Select } from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import ConfirmWindow from '~/components/ConfirmWindow';

import PermissionComponent from '~/components/PermissionComponent';
import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  BasicInfo,
  Addresses,
  Address,
  Contacts,
  RelatedList,
  Related,
  Tags,
  Audit,
} from './styles';
import LimitClientsModal from './LimitClientsModal';
import Report from './Report';

const List = () => {
  const { company } = useAuth();

  const { state } = useLocation();

  let matchParamsId;

  if (state) {
    matchParamsId = state.id;
  } else {
    matchParamsId = null;
  }

  const filterRef = useRef(null);

  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [clientIndex, setClientIndex] = useState(0);
  const [totalClients, setTotalClients] = useState(0);

  const [totalClientsCompanyHas, setTotalClientsCompanyHas] = useState(0);
  const [isOpenLimitClientModal, setIsOpenLimitClientModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(() => {
    const currentPageStorage = sessionStorage.getItem('@Diretiva:client:page');

    if (currentPageStorage) {
      return Number(currentPageStorage);
    }

    return 1;
  });
  const [totalPages, setTotalPages] = useState(1);

  const [nameSearch, setNameSearch] = useState(() => {
    const nameStorage = localStorage.getItem('@Diretiva:client:filter:name');

    if (nameStorage) {
      return nameStorage;
    }

    return '';
  });
  const [selectedDocumentType, setSelectedDocumentType] = useState(() => {
    const documentTypeStorage = localStorage.getItem(
      '@Diretiva:client:filter:document_type'
    );

    if (documentTypeStorage) {
      return JSON.parse(documentTypeStorage);
    }

    return { value: 2, label: 'CNPJ' };
  });
  const [selectedTaxationType, setSelectedTaxationType] = useState(() => {
    const taxationTypeStorage = localStorage.getItem(
      '@Diretiva:client:filter:taxation_type'
    );

    if (taxationTypeStorage) {
      return JSON.parse(taxationTypeStorage);
    }

    return { value: '', label: 'Todos' };
  });
  const [selectedSituation, setSelectedSituation] = useState(() => {
    const situationStorage = localStorage.getItem(
      '@Diretiva:client:filter:situation'
    );

    if (situationStorage) {
      return JSON.parse(situationStorage);
    }

    return { value: 1, label: 'Ativo' };
  });
  const [selectedClientType, setSelectedClientType] = useState(() => {
    const clientTypeStorage = localStorage.getItem(
      '@Diretiva:client:filter:client_type'
    );

    if (clientTypeStorage) {
      return JSON.parse(clientTypeStorage);
    }

    return { value: 0, label: 'Regular' };
  });
  const [selectedContractType, setSelectedContractType] = useState(() => {
    const contractTypeStorage = localStorage.getItem(
      '@Diretiva:client:filter:contract_type'
    );

    if (contractTypeStorage) {
      return JSON.parse(contractTypeStorage);
    }

    return { value: 2, label: 'Próprio' };
  });

  const loadClients = useCallback(async () => {
    if (company) {
      try {
        if (matchParamsId) {
          setShowTable(false);
          setLoading(true);

          const response = await api.get(`relationships/${matchParamsId}`);

          const { data } = response;

          const formattedClient = {
            ...data,
            birthday: data.birthday
              ? format(parseISO(data.birthday), 'dd/MM/yyyy')
              : '',
            start_day: data.start_day
              ? format(parseISO(data.start_day), 'dd/MM/yyyy')
              : '',
            end_day: data.end_day
              ? format(parseISO(data.end_day), 'dd/MM/yyyy')
              : '',
            document: data.document
              ? formatDocument(data.document_type, data.document)
              : '',
            cei: data.cei ? formatDocument(null, data.cei) : '',
            phone: data.phone
              ? formatContact(2, data.phone.replace(/[^0-9]+/g, ''))
              : '',
            addresses:
              data.addresses.length > 0 &&
              data.addresses.map(address => ({
                ...address,
                complement:
                  address.complement === 'Array' ? '' : address.complement,
              })),
            contacts:
              data.contacts.length > 0 &&
              data.contacts.map(contact => ({
                ...contact,
                content: contact.content
                  ? formatContact(
                      contact.type,
                      contact.type !== 1
                        ? contact.content.replace(/[^0-9]+/g, '')
                        : contact.content
                    )
                  : '',
              })),
            related:
              data.related.length > 0 &&
              data.related.map(related => ({
                id: related.id,
                dpt_labour: related.dpt_labour,
                dpt_tax: related.dpt_tax,
                dpt_accounting: related.dpt_accounting,
                dpt_financial: related.dpt_financial,
                dpt_admin: related.dpt_admin,
                responsible: related.responsible,
                name: related.info?.name || '',
                document: related.info?.document
                  ? formatDocument(
                      related.info?.document_type,
                      related.info?.document
                    )
                  : '',
                birthday: related.info?.birthday
                  ? format(parseISO(related.info?.birthday), 'dd/MM/yyyy')
                  : '',
                email:
                  related.related_contacts.length > 0 &&
                  findAndFormatContact(related.related_contacts, 'email'),
                contact:
                  related.related_contacts.length > 0 &&
                  findAndFormatContact(related.related_contacts, 'contact'),
              })),
          };

          setClient(formattedClient);
        } else {
          setShowTable(true);
          setLoading(true);

          const response = await api.get('relationships', {
            params: {
              company_id: company.id,
              page: currentPage,
              type: 1,
              search: nameSearch,
              document_type: selectedDocumentType.value,
              active: selectedSituation.value,
              client_type: selectedClientType.value,
              contract_type: selectedContractType.value,
              taxation_type: selectedTaxationType.value,
            },
          });

          if (response.data.docs.length > 0) {
            const formattedClients = response.data.docs.map(item => ({
              ...item,
              birthday: item.birthday
                ? format(parseISO(item.birthday), 'dd/MM/yyyy')
                : '',
              start_day: item.start_day
                ? format(parseISO(item.start_day), 'dd/MM/yyyy')
                : '',
              end_day: item.end_day
                ? format(parseISO(item.end_day), 'dd/MM/yyyy')
                : '',
              contract_date: item.contract_date
                ? format(parseISO(item.contract_date), 'dd/MM/yyyy')
                : '',
              document: item.document
                ? formatDocument(item.document_type, item.document)
                : '',
              cei: item.cei ? formatDocument(null, item.cei) : '',
              phone: item.phone
                ? formatContact(2, item.phone.replace(/[^0-9]+/g, ''))
                : '',
              addresses:
                item.addresses.length > 0 &&
                item.addresses.map(address => ({
                  ...address,
                  complement:
                    address.complement === 'Array' ? '' : address.complement,
                })),
              contacts:
                item.contacts.length > 0 &&
                item.contacts.map(contact => ({
                  ...contact,
                  content: contact.content
                    ? formatContact(
                        contact.type,
                        contact.type !== 1
                          ? contact.content.replace(/[^0-9]+/g, '')
                          : contact.content
                      )
                    : '',
                })),
              related:
                item.related.length > 0 &&
                item.related.map(related => ({
                  id: related.id,
                  dpt_labour: related.dpt_labour,
                  dpt_tax: related.dpt_tax,
                  dpt_accounting: related.dpt_accounting,
                  dpt_financial: related.dpt_financial,
                  dpt_admin: related.dpt_admin,
                  responsible: related.responsible,
                  name: (related.info && related.info.name) || '',
                  treatment_name:
                    (related.info && related.info.treatment_name) || '',
                  document: related.info.document
                    ? formatDocument(
                        related.info?.document_type,
                        related.info?.document
                      )
                    : '',
                  birthday: related.info?.birthday
                    ? format(parseISO(related.info?.birthday), 'dd/MM/yyyy')
                    : '',
                  email:
                    related.related_contacts.length > 0
                      ? findAndFormatContact(related.related_contacts, 'email')
                      : '',
                  contact:
                    related.related_contacts.length > 0
                      ? findAndFormatContact(
                          related.related_contacts,
                          'contact'
                        )
                      : '',
                })),
              audit:
                item.audit.length > 0 &&
                item.audit.map(audit => ({
                  ...audit,
                  formatted_audit_date: format(
                    parseISO(audit.created_at),
                    "dd/MM/yyyy 'às' HH:mm"
                  ),
                })),
            }));

            setClients(formattedClients);
            if (!matchParamsId) {
              setClient(formattedClients[0]);
            }
            setTotalPages(response.data.pages);
            setTotalClients(response.data.total);
          } else {
            setClients([]);
            setClient({});
            setTotalPages(1);
            setTotalClients(0);
            toast.warn('Nenhum cliente foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }

        setLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLoading(false);
      }
    }
  }, [
    company,
    currentPage,
    nameSearch,
    selectedDocumentType.value,
    selectedSituation.value,
    selectedClientType.value,
    selectedContractType.value,
    selectedTaxationType.value,
    matchParamsId,
  ]);

  useEffect(() => {
    async function loadTotalClients() {
      if (company) {
        const response = await api.get(
          `/relationships/quant-clients/${company.id}`
        );

        setTotalClientsCompanyHas(response.data);
      }
    }

    loadTotalClients();
    loadClients();
  }, [loadClients, company]);

  const documentTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'CPF' },
      { value: 2, label: 'CNPJ' },
    ];
  }, []);

  const situationOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Ativo' },
      { value: 0, label: 'Inativo' },
    ];
  }, []);

  const clientTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Regular' },
      { value: 1, label: 'Avulso' },
    ];
  }, []);

  const contractTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 1, label: 'Terceirizado' },
      { value: 2, label: 'Próprio' },
    ];
  }, []);

  const taxationTypeOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 6, label: 'Empregador PF' },
      { value: 5, label: 'Empregador Doméstico' },
      { value: 1, label: 'MEI' },
      { value: 11, label: 'MEI Somente Declarações' },
      { value: 7, label: 'Simples Nacional C/ Inscrição' },
      { value: 8, label: 'Simples Nacional S/ Inscrição' },
      { value: 3, label: 'Lucro Presumido' },
      { value: 4, label: 'Lucro Real' },
      { value: 9, label: 'Condomínio' },
      { value: 10, label: 'Terceiro Setor' },
    ];
  }, []);

  const alterView = useCallback(() => {
    if (matchParamsId) {
      history.push('/client', { id: null });
    }
    if (clients.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, clients.length, matchParamsId]);

  const handleFilter = useCallback(
    ({
      name,
      document_type,
      taxation_type,
      situation,
      client_type,
      contract_type,
    }) => {
      setCurrentPage(1);
      localStorage.setItem('@Diretiva:client:page', 1);

      setNameSearch(name);
      localStorage.setItem('@Diretiva:client:filter:name', name);

      setSelectedDocumentType(
        documentTypeOptions.find(option => option.value === document_type)
      );
      localStorage.setItem(
        '@Diretiva:client:filter:document_type',
        JSON.stringify(
          documentTypeOptions.find(option => option.value === document_type)
        )
      );

      setSelectedTaxationType(
        taxationTypeOptions.find(option => option.value === taxation_type)
      );
      localStorage.setItem(
        '@Diretiva:client:filter:taxation_type',
        JSON.stringify(
          taxationTypeOptions.find(option => option.value === taxation_type)
        )
      );

      setSelectedSituation(
        situationOptions.find(option => option.value === situation)
      );
      localStorage.setItem(
        '@Diretiva:client:filter:situation',
        JSON.stringify(
          situationOptions.find(option => option.value === situation)
        )
      );

      setSelectedClientType(
        clientTypeOptions.find(option => option.value === client_type)
      );
      localStorage.setItem(
        '@Diretiva:client:filter:client_type',
        JSON.stringify(
          clientTypeOptions.find(option => option.value === client_type)
        )
      );

      setSelectedContractType(
        contractTypeOptions.find(option => option.value === contract_type)
      );
      localStorage.setItem(
        '@Diretiva:client:filter:contract_type',
        JSON.stringify(
          contractTypeOptions.find(option => option.value === contract_type)
        )
      );
    },
    [
      documentTypeOptions,
      taxationTypeOptions,
      situationOptions,
      clientTypeOptions,
      contractTypeOptions,
    ]
  );

  const resetFilter = useCallback(() => {
    setCurrentPage(1);
    localStorage.setItem('@Diretiva:client:page', 1);

    filterRef.current.clearField('name');
    setNameSearch('');
    localStorage.removeItem('@Diretiva:client:filter:name');

    filterRef.current.setFieldValue('document_type', {
      value: 2,
      label: 'CNPJ',
    });
    setSelectedDocumentType({
      value: 2,
      label: 'CNPJ',
    });
    localStorage.removeItem('@Diretiva:client:filter:document_type');

    filterRef.current.setFieldValue('taxation_type', {
      value: '',
      label: 'Todos',
    });
    setSelectedTaxationType({
      value: '',
      label: 'Todos',
    });
    localStorage.removeItem('@Diretiva:client:filter:taxation_type');

    filterRef.current.setFieldValue('situation', {
      value: 1,
      label: 'Ativo',
    });
    setSelectedSituation({
      value: 1,
      label: 'Ativo',
    });
    localStorage.removeItem('@Diretiva:client:filter:situation');

    filterRef.current.setFieldValue('client_type', {
      value: 0,
      label: 'Regular',
    });
    setSelectedClientType({
      value: 0,
      label: 'Regular',
    });
    localStorage.removeItem('@Diretiva:client:filter:client_type');

    filterRef.current.setFieldValue('contract_type', {
      value: 2,
      label: 'Próprio',
    });
    setSelectedContractType({
      value: 2,
      label: 'Próprio',
    });
    localStorage.removeItem('@Diretiva:client:filter:contract_type');
  }, [filterRef]);

  const getDetails = useCallback(
    (id, index) => {
      const item = clients.find(clientItem => clientItem.id === id);

      setClient(item);
      setClientIndex(index);
      alterView();
    },
    [clients, alterView]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
        sessionStorage.setItem('@Diretiva:client:page', 1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
        sessionStorage.setItem('@Diretiva:client:page', totalPages);
      } else {
        setCurrentPage(page);
        sessionStorage.setItem('@Diretiva:client:page', page);
      }
    },
    [totalPages]
  );

  const handlePrevItem = useCallback(() => {
    if (clientIndex !== 0) {
      setClientIndex(clientIndex - 1);
      setClient(clients[clientIndex - 1]);
    } else {
      setClientIndex(clients.length - 1);
      setClient(clients[clients.length - 1]);
    }
  }, [clientIndex, clients]);

  const handleNextItem = useCallback(() => {
    if (clientIndex !== clients.length - 1) {
      setClientIndex(clientIndex + 1);
      setClient(clients[clientIndex + 1]);
    } else {
      setClientIndex(0);
      setClient(clients[0]);
    }
  }, [clientIndex, clients]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`relationships/${id}`);

        setClients(oldClients => oldClients.filter(item => item.id !== id));

        alterView();

        loadClients();

        toast.success('Cliente deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar cliente.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [alterView, loadClients]
  );

  const confirmDelete = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => handleDelete(client.id)}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleDelete, client]);

  // Nova função para inativar o cliente PJ
  const handleInativarPJ = useCallback(async () => {
    try {
      await api.post(`relationships/inactivate/${client.id}`);
      toast.success('Cliente inativado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      loadClients();
    } catch (err) {
      toast.error('Falha ao inativar cliente.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [client, loadClients]);

  // Função de confirmação para inativação
  const confirmInativarPJ = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            message="Você tem certeza que deseja inativar este cliente?"
            onClick={() => {
              handleInativarPJ();
              onClose();
            }}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleInativarPJ]);

  const limitClientModalType = useRef(null);
  const handleAddClient = useCallback(() => {
    if (
      (totalClientsCompanyHas.regular < company.client_limit &&
        totalClientsCompanyHas.loose < company.client_limit) ||
      !company.client_limit
    ) {
      history.push('/client/new', { id: null });
    } else if (totalClientsCompanyHas.regular < company.client_limit) {
      limitClientModalType.current = 0;
      setIsOpenLimitClientModal(true);
    } else if (totalClientsCompanyHas.loose < company.client_limit) {
      limitClientModalType.current = 1;
      setIsOpenLimitClientModal(true);
    } else {
      limitClientModalType.current = 2;
      setIsOpenLimitClientModal(true);
    }
  }, [company, totalClientsCompanyHas]);

  const searchByName = useCallback(() => {
    const name = filterRef.current.getFieldValue('name');

    setCurrentPage(1);
    setNameSearch(name);
    setSelectedDocumentType({ value: '', label: 'Todos' });
    setSelectedTaxationType({ value: '', label: 'Todos' });
    setSelectedSituation({ value: '', label: 'Todos' });
    setSelectedClientType({ value: '', label: 'Todos' });
    setSelectedContractType({ value: '', label: 'Todos' });
  }, []);

  const [reportLoading, setReportLoading] = useState(false);
  const [clientsForReport, setClientsForReport] = useState([]);
  const printReport = async () => {
    try {
      setReportLoading(true);

      const response = await api.get('relationships', {
        params: {
          company_id: company.id,
          type: 1,
          search: nameSearch,
          document_type: selectedDocumentType.value,
          active: selectedSituation.value,
          client_type: selectedClientType.value,
          contract_type: selectedContractType.value,
          taxation_type: selectedTaxationType.value,
          reportOnly: true,
        },
      });

      if (response.data.length > 0) {
        const formattedClients = response.data.map(item => ({
          ...item,
          contract_date: item.contract_date
            ? format(parseISO(item.contract_date), 'dd/MM/yyyy')
            : '',
          client_type: item.client_type === 0 ? 'Regular' : 'Avulso',
          contract_type:
            item.client_type === 0
              ? item.contract_type === 1
                ? 'Terceirizado'
                : 'Próprio'
              : '',
          document: item.document
            ? formatDocument(item.document_type, item.document)
            : '',
        }));

        setClientsForReport(formattedClients);
      } else {
        toast.warn('Nenhum cliente para gerar relatório encontrado.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (err) {
      toast.error('Falha ao gerar relatório.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    if (clientsForReport.length === 0) return;

    window.print();
  }, [clientsForReport]);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaAddressCard size={20} color="#44546a" />
            <h1>Clientes</h1>
          </div>

          <Link to="/">
            <FaTimes size={20} color="#44546a" />
          </Link>
        </Header>
        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <button type="button" onClick={handleAddClient}>
            <FaPlus />
            <span>Novo</span>
          </button>
          {showTable ? (
            <>
              <button type="button" onClick={resetFilter}>
                <FaEraser />
                <span>Limpar filtros</span>
              </button>
              <button type="button" onClick={printReport}>
                <FaClipboard />
                <span>Relatório</span>
              </button>
            </>
          ) : (
            <>
              <PermissionComponent level={5}>
                <Link
                  to={{ pathname: '/client/edit', state: { id: client.id } }}
                >
                  <FaEdit />
                  <span>Editar</span>
                </Link>
              </PermissionComponent>
              <PermissionComponent level={9}>
                <button type="button" onClick={confirmDelete}>
                  <FaTrash />
                  <span>Excluir</span>
                </button>
              </PermissionComponent>
              <PermissionComponent level={6}>
                <button type="button" onClick={confirmInativarPJ}>
                  <FaThumbsDown />
                  <span>Inativar PJ</span>
                </button>
              </PermissionComponent>
              {!matchParamsId && (
                <div>
                  <button type="button" onClick={handlePrevItem}>
                    <FaChevronLeft />
                  </button>
                  {totalClients > 25 ? (
                    <span>{clientIndex + 1} de 25</span>
                  ) : (
                    <span>
                      {clientIndex + 1} de {totalClients}
                    </span>
                  )}
                  <button type="button" onClick={handleNextItem}>
                    <FaChevronRight />
                  </button>
                </div>
              )}
            </>
          )}
        </Controls>
        {showTable && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <Input
              name="name"
              className="name"
              label="Nome"
              defaultValue={nameSearch}
            />
            <button
              type="button"
              onClick={searchByName}
              className="search-name"
            >
              <FaSearch />
              <span>N</span>
            </button>
            <Select
              name="document_type"
              className="document_type"
              label="Documento"
              options={documentTypeOptions}
              defaultValue={selectedDocumentType}
            />
            <Select
              name="taxation_type"
              className="taxation_type"
              label="Tributação"
              options={taxationTypeOptions}
              defaultValue={selectedTaxationType}
            />
            <Select
              label="Situação"
              name="situation"
              className="situation"
              options={situationOptions}
              defaultValue={selectedSituation}
            />
            <Select
              label="Tipo de cliente"
              name="client_type"
              className="client_type"
              options={clientTypeOptions}
              defaultValue={selectedClientType}
            />
            <Select
              label="Tipo de contrato"
              name="contract_type"
              className="contract_type"
              options={contractTypeOptions}
              defaultValue={selectedContractType}
            />
            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}

        {loading || !company ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="active">Ativo</th>
                    <th className="document">CPF/CNPJ</th>
                    <th className="name">Nome</th>
                    <th className="state_registration">Inscrição Estadual</th>
                    <th className="cei">CEI/CAEPF</th>
                    <th className="group">Grupo</th>
                    <th className="city">Cidade</th>
                    <th className="client_type">Tipo de Cliente</th>
                    <th className="contract_type">Tipo de Contrato</th>
                  </tr>
                </thead>
                <tbody>
                  {clients &&
                    clients.map((clientItem, index) => (
                      <tr
                        key={clientItem.id}
                        className="hover"
                        onClick={() => getDetails(clientItem.id, index)}
                      >
                        <td className="active">
                          {clientItem?.active ? (
                            <FaThumbsUp color="#006229" />
                          ) : (
                            <FaThumbsDown color="#E53935" />
                          )}
                        </td>
                        <td className="document">{clientItem?.document}</td>
                        <td className="name">{clientItem?.name}</td>
                        <td className="state_registration">
                          {clientItem?.state_registration}
                        </td>
                        <td className="cei">{clientItem?.cei}</td>
                        <td className="group">
                          {(clientItem?.group_relationship &&
                            clientItem?.group_relationship.name) ||
                            ''}
                        </td>
                        <td className="city">
                          {clientItem.addresses[0]?.city}
                        </td>
                        <td className="client_type">
                          {clientItem?.client_type_label}
                        </td>
                        <td className="contract_type">
                          {clientItem?.contract_type_label}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer>
                <BasicInfo>
                  <h4>INFORMAÇÕES BÁSICAS</h4>
                  <section>
                    <div className="document_type">
                      <label>PF/PJ</label>
                      <input
                        name="document_type"
                        value={client.document_type === 1 ? 'CPF' : 'CNPJ'}
                        readOnly
                      />
                    </div>
                    <div className="document">
                      <label>
                        {client.document_type === 1 ? 'CPF' : 'CNPJ'}
                      </label>
                      <input name="document" value={client.document} readOnly />
                    </div>
                    <div className="name">
                      <label>Nome</label>
                      <input name="name" value={client.name} readOnly />
                    </div>
                    <div className="client_type">
                      <label>Tipo de cliente</label>
                      <input
                        name="client_type"
                        value={client.client_type_label}
                        readOnly
                      />
                    </div>
                  </section>
                  <section>
                    <div className="nickname">
                      <label>Nome abreviado</label>
                      <input name="nickname" value={client.nickname} readOnly />
                    </div>
                    <div className="state_registration">
                      <label>
                        {client.document_type === 1
                          ? 'Identidade'
                          : 'Inscrição Estadual'}
                      </label>
                      <input
                        name="state_registration"
                        value={client.state_registration || ''}
                        readOnly
                      />
                    </div>
                    <div className="cei">
                      <label>CEI/CAEPF</label>
                      <input name="cei" value={client.cei} readOnly />
                    </div>
                    {client.document_type === 2 && (
                      <div className="phone">
                        <label>Telefone Geral</label>
                        <input name="phone" value={client.phone} readOnly />
                      </div>
                    )}
                    <div className="group">
                      <label>Grupo</label>
                      <input
                        name="group"
                        value={
                          (client.group_relationship &&
                            client.group_relationship.name) ||
                          ''
                        }
                        readOnly
                      />
                    </div>
                  </section>
                  <section>
                    <div className="birthday">
                      <label>
                        {client.document_type === 1 ? 'Nascimento' : 'Fundação'}
                      </label>
                      <input name="birthday" value={client.birthday} readOnly />
                    </div>
                    <div className="contract_date">
                      <label>Data do contrato</label>
                      <input
                        name="contract_date"
                        value={client.contract_date}
                        readOnly
                      />
                    </div>
                    <div className="start_day">
                      <label>Data de implantação</label>
                      <input
                        name="start_day"
                        value={client.start_day}
                        readOnly
                      />
                    </div>
                    <div className="protocol_preference">
                      <label>Preferência de protocolo</label>
                      <input
                        name="protocol_preference"
                        value={client.protocol_preference_label}
                        readOnly
                      />
                    </div>
                    <div className="tributes_payment">
                      <label>Pgto de Tributos</label>
                      <input
                        name="tributes_payment"
                        value={client.tributes_payment_label}
                        readOnly
                      />
                    </div>
                  </section>
                  <section>
                    <div className="acquisition_type">
                      <label>Tipo de Aquisição</label>
                      <input
                        name="acquisition_type"
                        value={
                          (client.acquisition && client.acquisition.label) || ''
                        }
                        readOnly
                      />
                    </div>
                    <div className="closing_tax">
                      <label>Taxa de encerramento</label>
                      <input
                        name="closing_tax"
                        checked={client.closing_tax}
                        type="checkbox"
                        readOnly
                      />
                    </div>
                    <div className="active">
                      <label>Situação</label>
                      <input
                        name="active"
                        value={client.active ? 'Ativo' : 'Inativo'}
                        readOnly
                      />
                    </div>
                    {!client.active && (
                      <div className="end_day">
                        <label>Encerramento</label>
                        <input name="end_day" value={client.end_day} readOnly />
                      </div>
                    )}
                    {!client.active && (
                      <div className="end_day">
                        <label>Tipo de encerramento</label>
                        <input
                          name="inactive_type"
                          value={client.inactive ? client.inactive.label : ''}
                          readOnly
                        />
                      </div>
                    )}
                  </section>
                </BasicInfo>
                {client.addresses && client.addresses.length > 0 && (
                  <Addresses>
                    <h4>ENDEREÇOS</h4>
                    {client.addresses.map(address => (
                      <Address key={address.id}>
                        <section>
                          <div className="type">
                            <label>Tipo</label>
                            <input
                              name="type"
                              value={address.type_label}
                              readOnly
                            />
                          </div>
                          <div className="zipcode">
                            <label>CEP</label>
                            <input
                              name="zipcode"
                              value={address.zipcode}
                              readOnly
                            />
                          </div>
                          <div className="street">
                            <label>Endereço</label>
                            <input
                              name="street"
                              value={address.street}
                              readOnly
                            />
                          </div>
                          <div className="number">
                            <label>Nº</label>
                            <input
                              name="number"
                              value={address.number}
                              readOnly
                            />
                          </div>
                        </section>
                        <section>
                          <div className="complement">
                            <label>Complemento</label>
                            <input
                              name="complement"
                              value={address.complement}
                              readOnly
                            />
                          </div>
                          <div className="neighborhood">
                            <label>Bairro</label>
                            <input
                              name="neighborhood"
                              value={address.neighborhood}
                              readOnly
                            />
                          </div>
                          <div className="city">
                            <label>Cidade</label>
                            <input name="city" value={address.city} readOnly />
                          </div>
                          <div className="state">
                            <label>Estado</label>
                            <input
                              name="state"
                              value={address.state_label}
                              readOnly
                            />
                          </div>
                        </section>
                      </Address>
                    ))}
                  </Addresses>
                )}
                {client.contacts && client.contacts.length > 0 && (
                  <Contacts>
                    <h4>CONTATOS</h4>
                    {client.contacts.map(contact => (
                      <section key={contact.id}>
                        <div className="contact_type">
                          <label>Tipo</label>
                          <input
                            name="contact_type"
                            value={contact.type_label}
                            readOnly
                          />
                        </div>
                        <div className="content">
                          <label>{contact.type_label}</label>
                          <input
                            name="content"
                            value={contact.content}
                            readOnly
                          />
                        </div>
                      </section>
                    ))}
                  </Contacts>
                )}
                {client.related && client?.related?.length > 0 && (
                  <RelatedList>
                    <h4>PESSOAS RELACIONADAS</h4>
                    {client.related.map(related => (
                      <Related key={related.id}>
                        <section>
                          <div className="related_document">
                            <label>CPF</label>
                            <input
                              name="related_document"
                              value={related.document}
                              readOnly
                            />
                          </div>
                          <div className="related_name">
                            <label>Nome</label>
                            <input
                              name="related_name"
                              value={related.name}
                              readOnly
                            />
                          </div>
                          <div className="related_treatment_name">
                            <label>Tratamento</label>
                            <input
                              name="related_treatment_name"
                              value={related.treatment_name}
                              readOnly
                            />
                          </div>
                          <div className="related_birthday">
                            <label>Nascimento</label>
                            <input
                              name="related_birthday"
                              value={related.birthday}
                              readOnly
                            />
                          </div>
                          <div className="related_email">
                            <label>E-mail</label>
                            <input
                              name="related_email"
                              value={related.email}
                              readOnly
                            />
                          </div>
                          <div className="related_contact">
                            <label>Telefone</label>
                            <input
                              name="related_contact"
                              value={related.contact}
                              readOnly
                            />
                          </div>
                        </section>
                        <section>
                          <div className="dpt_labour">
                            <label>Trabalhista</label>
                            <input
                              name="dpt_labour"
                              checked={related.dpt_labour}
                              type="checkbox"
                              readOnly
                            />
                          </div>
                          <div className="dpt_tax">
                            <label>Tributário</label>
                            <input
                              name="dpt_tax"
                              checked={related.dpt_tax}
                              type="checkbox"
                              readOnly
                            />
                          </div>
                          <div className="dpt_accounting">
                            <label>Contábil</label>
                            <input
                              name="dpt_accounting"
                              checked={related.dpt_accounting}
                              type="checkbox"
                              readOnly
                            />
                          </div>
                          <div className="dpt_financial">
                            <label>Financeiro</label>
                            <input
                              name="dpt_financial"
                              checked={related.dpt_financial}
                              type="checkbox"
                              readOnly
                            />
                          </div>
                          <div className="dpt_admin">
                            <label>Administração</label>
                            <input
                              name="dpt_admin"
                              checked={related.dpt_admin}
                              type="checkbox"
                              readOnly
                            />
                          </div>
                          <div className="responsible">
                            <label>Sócio responsável</label>
                            <input
                              name="responsible"
                              checked={related.responsible}
                              type="checkbox"
                              readOnly
                            />
                          </div>
                        </section>
                      </Related>
                    ))}
                  </RelatedList>
                )}
                {client.tags && client.tags.length > 0 && (
                  <Tags>
                    <h4>TAGS</h4>
                    {client.tags.map(tag => (
                      <section key={tag.id}>
                        <div className="tag">
                          <label>Tag</label>
                          <input name="tag" value={tag.tag} readOnly />
                        </div>
                      </section>
                    ))}
                  </Tags>
                )}
                {client.audit && client.audit.length > 0 && (
                  <Audit>
                    <h4>LOGS</h4>
                    {client.audit.map(audit => (
                      <section key={audit.id}>
                        <div className="date">
                          <label>Data/Hora</label>
                          <input
                            name="formatted_audit_date"
                            value={audit.formatted_audit_date}
                            readOnly
                          />
                        </div>
                        <div className="user">
                          <label>Usuário</label>
                          <input
                            name="audit_user"
                            value={audit.user.short_name}
                            readOnly
                          />
                        </div>
                        <div className="action">
                          <label>Ação</label>
                          <input
                            name="action"
                            value={audit.action_label}
                            readOnly
                          />
                        </div>
                      </section>
                    ))}
                  </Audit>
                )}
              </DetailsContainer>
            )}
          </Content>
        )}
        <Pagination
          loading={loading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalClients}
          handlePage={handlePage}
        />
      </Container>
      {(deleteLoading || reportLoading) && <Loading />}

      {isOpenLimitClientModal && (
        <LimitClientsModal
          onClick={() => history.push('/support')}
          goCreate={() => history.push('/client/new', { id: null })}
          setIsOpen={() => setIsOpenLimitClientModal(false)}
          type={limitClientModalType.current}
        />
      )}

      <Report clients={clientsForReport} />
    </>
  );
};

export default List;
